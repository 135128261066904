import React, { useCallback, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import {
  Box,
  Button,
  ButtonSize,
  CARD_HEADING,
  CLTextField,
  Loader,
  SUB_TITLE,
  SnackBar,
  SnackBarVariant,
  Typo,
} from "../../CLComponents";
import {
  getAccessToken,
  getUserDataFromLinkedin,
} from "../../Services/API/Auth";
import { useAuth } from "../../Context/userContext";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  INVALID_EMAIL,
  NOT_ABLE_TO_VALIDATE,
  REQUIRED_FIELD,
  SUCCESSFULLY_VALIDATED_DATA,
  SUCCESSFUL_LOGIN,
  TRY_LETTER,
  VALIDATING_DATA,
} from "../../Utils/Messages";
import "./LoginForm.css";
import { validateVanity } from "../../Utils/ValidateVanity";
import { BASE_URL } from "../../config/Routes/Constants";

function LoginForm() {
  const {
    globalLoader,
    setGlobalLoader,
    fetchUserData,
    setAuthed,
    setUserProfileData,
  } = useAuth();
  const navigate = useNavigate();

  //? form field ref
  const emailRef = useRef(null);
  const vanityRef = useRef(null);

  const [code, setCode] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [vanityError, setVanityError] = useState(null);

  //? states for snackbars
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  const [msgType, setMsgType] = useState(SnackBarVariant.SUCCESS);
  const [allowAutoHide, setAllowAutoHide] = useState(true);

  //? check whether code is available or not if available then store it in localStorage
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isLinkedinTokenAvailable = localStorage.getItem("linkedin_token");

    if (urlParams.get("code") && !isLinkedinTokenAvailable) {
      setCode(urlParams.get("code"));
      setGlobalLoader(true);
      handleSnackBar(VALIDATING_DATA, SnackBarVariant.INFO);
      getAccessToken({
        code: urlParams.get("code"),
      })
        .then((data) => {
          const { access_token, email, vanity } = data.data;
          localStorage.setItem("linkedin_token", access_token);
          if (access_token && email && vanity) {
            fetchUserData(false)
              .then((userData) => {
                const { data } = userData.data;
                handleSnackBar(SUCCESSFULLY_VALIDATED_DATA);
                setTimeout(() => {
                  setUserProfileData(data);
                  // setGlobalLoader(false);
                  //? belowed state update will redirect to the "/". check ParamBasedRoute file for more details
                  setAuthed(true);
                  setGlobalLoader(false);
                }, 1000);
              })
              .catch((error) => {
                // setGlobalLoader(false);
                return false;
              });
          } else {
            setGlobalLoader(false);
            // handleSnackBar(NOT_ABLE_TO_VALIDATE, SnackBarVariant.ERROR);
            if (access_token && email) {
              emailRef.current.value = email;
            }
          }
        })
        .catch((error) => {
          const { detail } = error?.response?.data;
          handleSnackBar(detail || TRY_LETTER, SnackBarVariant.ERROR, false);
          // TODO: this code can be reduce
          setGlobalLoader(false);
          setTimeout(() => {
            navigate(BASE_URL);
          }, 5000);
        });
    }
  }, []);

  const handleSnackBar = useCallback(
    (msg, msgType = SnackBarVariant.SUCCESS, allowAutoHide = true) => {
      setSnackBarMsg(msg);
      setMsgType(msgType);
      setAllowAutoHide(allowAutoHide);
      setIsSnackBarOpen(true);
    },
    []
  );

  //? API call to generate and store data in DB
  const getData = useCallback(
    (email, vanity) => {
      // TODO: change hard coded email and vanity
      if (email && vanity && code) {
        setGlobalLoader(true);
        getUserDataFromLinkedin({
          access_token: localStorage.getItem("linkedin_token") || "",
          email: email,
          vanity_name: vanity,
        })
          .then((data) => {
            const { access_token } = data.data;
            if (access_token) {
              fetchUserData(false)
                .then((userData) => {
                  const { data } = userData.data;
                  handleSnackBar(SUCCESSFUL_LOGIN);
                  setTimeout(() => {
                    setUserProfileData(data);
                    setGlobalLoader(false);
                    //? belowed state update will redirect to the "/". check ParamBasedRoute file for more details
                    setAuthed(true);
                  }, 5000);
                })
                .catch((error) => {
                  setGlobalLoader(false);
                  return false;
                });
            }
          })
          .catch((error) => {
            const { detail } = error?.response?.data;
            handleSnackBar(detail || TRY_LETTER, SnackBarVariant.ERROR, false);
            // TODO: this code can be reduce
            setGlobalLoader(false);

            // setTimeout(() => {
            //   navigate(BASE_URL);
            // }, 5000);
          });
      }
    },
    [code, globalLoader]
  );

  const handleUserDetails = useCallback(() => {
    // e.preventDefault();
    window.dataLayer.push({
      event: "detail_page_button",
    });

    //? RegEX to check entered email format
    const emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

    const isValidVanity = validateVanity(vanityRef.current.value);

    //? if email or vanity is empty or not valid format show the error
    if (
      !emailRef.current.value ||
      !emailRegx.test(emailRef.current.value) ||
      !isValidVanity["validVanity"]
    ) {
      !emailRef.current.value
        ? setEmailError(REQUIRED_FIELD)
        : !emailRegx.test(emailRef.current.value)
        ? setEmailError(INVALID_EMAIL)
        : setEmailError(null);

      if (!isValidVanity["validVanity"]) {
        setVanityError(isValidVanity["errMsg"]);
      }
      return false;
    }
    setGlobalLoader(true);
    getData(emailRef.current.value, isValidVanity["vanity"]);
  }, [getData]);

  //? clear the error message when user start typing(if available)
  const clearErrorMsg = useCallback(() => {
    if (emailError || vanityError) {
      setEmailError(null);
      setVanityError(null);
    }
  }, [emailError, vanityError]);

  return (
    <>
      <Box className="full-screen-container centered-element">
        {globalLoader ? <Loader size={50} /> : <></>}

        <Box className="login-form-box">
          <Typo typoFor={CARD_HEADING}>Complete Your Coverly Profile</Typo>
          <Typo typoFor={SUB_TITLE}>
            Help us personalize your experience by providing additional details
            below.
          </Typo>

          {/* <Box> */}
          <form
            className="user-details-form"
            onChange={clearErrorMsg}
            name="user-details"
          >
            <CLTextField
              id="email"
              placeholder="Enter your email"
              fullWidth={true}
              name="email-field"
              inputRef={emailRef}
              error={emailError}
              disabled={!!emailRef?.current?.value}
            />
            <CLTextField
              id="vanity-name"
              placeholder="Paste your LinkedIn profile link"
              fullWidth={true}
              name="email-field"
              inputRef={vanityRef}
              error={vanityError}
            />
            <Button
              size={ButtonSize.LARGE}
              fullWidth
              onClick={handleUserDetails}
            >
              Continue
            </Button>
          </form>
        </Box>
      </Box>

      <SnackBar
        msg={snackBarMsg}
        isOpen={isSnackBarOpen}
        msgType={msgType}
        snackBarManager={setIsSnackBarOpen}
        allowAutoHide={allowAutoHide}
      />
    </>
  );
}

export default LoginForm;
