import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { Box } from "../../CLComponents";
import "../../App.css";

function MainLayout({ children }) {
  return (
    <Box className="cover-letter-app">
      <Navbar />
      {children}
    </Box>
  );
}

export default MainLayout;
