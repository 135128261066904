import React from "react";
import { GENERATE_LETTER_PAGE } from "../../config/Routes/Constants";
import { Navigate } from "react-router-dom";
import MainLayout from "../../Layout/MainLayout/MainLayout";
import Home from "../../Components/Home/Home";
import { useAuth } from "../../Context/userContext";

export function Landing() {
  const { authed } = useAuth();

  return (
    <MainLayout>
      {authed ? <Navigate to={GENERATE_LETTER_PAGE} /> : <Home />}
      {/* <LoginForm /> */}
      {/* <LetterCard /> */}
    </MainLayout>
  );
}
