import { Modal } from "@mui/material";
import * as React from "react";
import { Box } from "../Box";
import "./Modal.css";

export const CLModal = React.memo(({ open, setOpen, children, ...rest }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      // className="modal-base"
      {...rest}
    >
      <Box className="modal">{children}</Box>
    </Modal>
  );
});

CLModal.displayName = "CLModal";
