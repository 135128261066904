export const SnackBarVariant = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
};

export const VerticalAign = {
  TOP: "top",
  BOTTOM: "bottom",
};

export const HorizontalAign = {
  LEFT: "left",
  RIGHT: "right",
};
