import * as React from "react";
import { Alert, Snackbar } from "@mui/material";
import { HorizontalAign, SnackBarVariant, VerticalAign } from "./types";

export const SnackBar = React.memo(
  ({
    isOpen,
    msg,
    snackBarManager,
    allowAutoHide = true,
    autoHideDuration = 5000,
    vertical = VerticalAign.TOP,
    horizontal = HorizontalAign.RIGHT,
    msgType = SnackBarVariant.SUCCESS,
    ...rest
  }) => {
    const handleClose = React.useCallback(() => {
      snackBarManager(false);
    }, [snackBarManager]);

    return (
      <Snackbar
        open={isOpen}
        anchorOrigin={{
          vertical: vertical,
          horizontal: horizontal,
        }}
        onClose={handleClose}
        {...(allowAutoHide ? { autoHideDuration: autoHideDuration } : {})}
        {...rest}
      >
        <Alert
          severity={msgType}
          sx={{ width: "100%" }}
          onClose={handleClose}
          {...rest}
        >
          {msg}
        </Alert>
      </Snackbar>
    );
  }
);

SnackBar.displayName = "SnackBar";
