import * as React from "react";
import { Menu as MUIMenu } from "@mui/material/";

export const Menu = React.memo(
  ({ id, anchorEl, manageAnchorEl, children, withArrow = true }) => {
    const handleClose = React.useCallback(() => {
      manageAnchorEl(null);
    }, []);

    return (
      <MUIMenu
        anchorEl={anchorEl}
        id={id}
        open={!!anchorEl}
        onClose={handleClose}
        //   onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": withArrow
              ? {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                }
              : {},
            "&:before": withArrow
              ? {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                }
              : {},
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {children}
      </MUIMenu>
    );
  }
);

Menu.displayName = "Menu";
