import React, { useCallback, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LogoutIcon from "@mui/icons-material/Logout";
import UpdateIcon from "@mui/icons-material/Update";
import { Divider, List, ListItem, MenuItem, Typography } from "@mui/material";
import {
  Box,
  Button,
  Drawer,
  ButtonVariant,
  CLAvatar,
  IconButton,
  ButtonSize,
  Loader,
  Menu,
  SnackBarVariant,
  SnackBar,
} from "../../CLComponents";
import { useAuth } from "../../Context/userContext";
import { Link } from "react-router-dom";
import "./Navbar.css";
import "./../../App.css";
import {
  BASE_URL,
  GENERATE_LETTER_PAGE,
  PERSONAL_DETAILS_FORM,
} from "../../config/Routes/Constants";
import NavbarContent from "../../PageContent/en/Components/Navbar.json";

const hideButtonPaths = [PERSONAL_DETAILS_FORM];

const DrawerList = ({
  isAuthenticated,
  userData,
  logout,
  handleSnackBar,
  sinIn,
}) => {
  return (
    <List>
      {isAuthenticated && userData ? (
        <>
          {" "}
          <ListItem className="centered-element drawer-list-text">
            {NavbarContent.greeting} {userData.first_name}
          </ListItem>
          <Divider />
          <ListItem disabled className="centered-element">
            {NavbarContent.userMenuBar.updateProfile}
          </ListItem>
          <Divider />
          <ListItem className="centered-element" onClick={logout}>
            {NavbarContent.userMenuBar.logout}
          </ListItem>
        </>
      ) : (
        <ListItem>
          <Button fullWidth={true} onClick={sinIn(handleSnackBar)}>
            {NavbarContent.signIn}
          </Button>
        </ListItem>
      )}
      {/* <Divider /> */}
      {/* <ListItem>
        <Button variant={ButtonVariant.TEXT} fullWidth={true}>
          {NavbarContent.plan}
        </Button>
      </ListItem> */}
    </List>
  );
};

const NavButton = ({
  isAuthenticated,
  userData,
  globalLoader,
  anchorEl,
  setAnchorEl,
  logout,
  handleSnackBar,
  sinIn,
}) => {
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  if (globalLoader) {
    return (
      <Loader
        backdropPosition="relative"
        backdropBackgroundColor="transparent"
      />
    );
  } else {
    if (isAuthenticated && userData) {
      const { first_name, last_name, profile_pic_url } = userData;
      return (
        <>
          <CLAvatar
            name={first_name + " " + last_name}
            profileImg={profile_pic_url}
            onClick={handleMenu}
          />
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            manageAnchorEl={setAnchorEl}
          >
            <MenuItem disabled>
              <UpdateIcon />
              <Typography style={{ marginLeft: "0.5rem" }}>
                {NavbarContent.userMenuBar.updateProfile}
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={logout}>
              <LogoutIcon />
              <Typography style={{ marginLeft: "0.5rem" }}>
                {NavbarContent.userMenuBar.logout}
              </Typography>
            </MenuItem>
          </Menu>
        </>
      );
    } else {
      return (
        <IconButton
          size={ButtonSize.REGULAR}
          variant={ButtonVariant.PRIMARY}
          onClick={sinIn(handleSnackBar)}
        >
          <LinkedInIcon sx={{ fontSize: "1.2rem", marginRight: "0.4rem" }} />
          {NavbarContent.signIn}
        </IconButton>
      );
    }
  }
};

function Navbar() {
  const queryString = window.location;
  const { pathname, search } = queryString;
  const urlParams = new URLSearchParams(search);

  const { authed, userProfileData, globalLoader, logout, sinIn } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  //? states for snackbars
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  const [msgType, setMsgType] = useState(SnackBarVariant.SUCCESS);
  const [allowAutoHide, setAllowAutoHide] = useState(true);

  const handleSnackBar = useCallback(
    (msg, msgType = SnackBarVariant.SUCCESS, allowAutoHide = true) => {
      setSnackBarMsg(msg);
      setMsgType(msgType);
      setAllowAutoHide(allowAutoHide);
      setIsSnackBarOpen(true);
    },
    []
  );

  return (
    <>
      <Box className="navbar-container">
        <Box className="nav-title">
          <Link
            to={authed ? GENERATE_LETTER_PAGE : BASE_URL}
            style={{ textDecoration: "none" }}
          >
            <img src="/Assets/coverly 1.svg" alt="coverly.ai" />
            {/* <Typography variant="h4" className="styled-title">
              Coverletter
            </Typography> */}
          </Link>
        </Box>
        {!hideButtonPaths.includes(pathname) && !urlParams.get("code") ? (
          <Box className="nav-buttons">
            <Box className="nav-btns">
              <NavButton
                isAuthenticated={authed}
                userData={userProfileData}
                globalLoader={globalLoader}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                logout={logout}
                handleSnackBar={handleSnackBar}
                sinIn={sinIn}
              />
            </Box>
            <Box className="nav-menu-icon">
              <MenuIcon
                onClick={() => {
                  setIsDrawerOpen(true);
                }}
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Drawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        DrawerList={
          <DrawerList
            isAuthenticated={authed}
            userData={userProfileData}
            logout={logout}
            handleSnackBar={handleSnackBar}
            sinIn={sinIn}
          />
        }
      />
      <SnackBar
        msg={snackBarMsg}
        isOpen={isSnackBarOpen}
        msgType={msgType}
        snackBarManager={setIsSnackBarOpen}
        allowAutoHide={allowAutoHide}
      />
    </>
  );
}

export default Navbar;
