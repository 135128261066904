import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { FormHelperText, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box } from "../Box";
import "../../App.css";
import { PRIMARY_COLOR } from "../../Utils/Theme";

const CLInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    // width: "28rem",
    borderRadius: 6,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E0E0",
    fontSize: 16,
    padding: "9px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(PRIMARY_COLOR, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const CLTextField = React.memo((props) => {
  const {
    defaultValue,
    id,
    placeholder,
    fullWidth,
    name,
    passwordType = false,
    error = false,
    ...rest
  } = props;
  return (
    <Box>
      <CLInput
        defaultValue={defaultValue}
        id={id}
        placeholder={placeholder}
        fullWidth={fullWidth}
        name={name}
        type={passwordType ? "password" : "text"}
        // inputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">
        //       <IconButton aria-label="toggle password visibility" edge="end">
        //         {true ? <VisibilityOff /> : <Visibility />}
        //       </IconButton>
        //     </InputAdornment>
        //   ),
        // }}
        endAdornment={
          passwordType ? (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" edge="end">
                {true ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          )
        }
        {...rest}
      />
      {error ? (
        <FormHelperText className="error-msg">{error}</FormHelperText>
      ) : (
        <></>
      )}
      {/* <FormHelperText>this is form helper text</FormHelperText> */}
    </Box>
  );
});

CLTextField.displayName = "CLTextField";
