import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Button, ButtonVariant, QUESTION_TEXT, Typo } from "../../CLComponents";
// import { Box } from '../../CLComponents'
import "./QuestionBox.css";

const QuestionBox = ({
  isClick,
  questions,
  isConfirm,
  handleAgree,
  setIsClick,
  isDialog,
  setIsDialog,
}) => {
  const handleClose = () => {
    setIsClick(false);
    setIsDialog(false);
  };
  return (
    <>
      <Box
        component="div"
        contentEditable={false}
        className="question-box"
        sx={{ filter: !isClick && "blur(10px)" }}
      >
        <Typo
          typoFor={QUESTION_TEXT}
          dangerouslySetInnerHTML={{ __html: questions }}
        />
      </Box>
      <Dialog
        open={isDialog && !isConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ backdropFilter: "blur(10px)" }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: "25px" }}>
          {"Unlock the Top 10 Questions Feature🌟"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ marginTop: "10px" }}
          >
            To unlock the <b>Top 10 Interview Questions</b> feature, please
            accept to automatically share a LinkedIn post about{" "}
            <b>Coverly.ai</b> on your Linkedin profile. This helps us grow while
            granting you access to this valuable resource{" "}
            <b>for interview preparation.</b>
            <br />
            <br />
            Here’s the post that will be automatically shared on your LinkedIn
            profile:
            <br />
            <br />
            {/* <img
                src="/Assets/linkedinPost.png"
                style={{ width: "70%",display:"block",margin:"0 auto" }}
                alt="Hle"
              /> */}
            <div className="linkedin-post-message">
              It's incredible to see new AI tools being developed and discovered
              every day. This one, in particular, is aimed at job hunters and
              can be a game-changer in a competitive market! 🚀
              <br />
              <br />
              🤖 Generates tailor-made cover letters in seconds
              <br />
              🎯 Aligns your skills & experiences with job requirements
              effortlessly
              <br />
              🔒 Provides top 10 questions you might encounter during specific
              job interviews
              <br />
              <br />
              Stay ahead and embrace the power of AI to help you stand out in a
              challenging job market!
              <br />
              <br />
              Give it a try: 👉 coverly.ai 🏆💼
              <br />
              <br />
              Are you using any AI tools in your work or job search? Share your
              experiences! 💬
              <br />
              <br />
              #AI #JobSearch #Career #CoverLetter #Innovation
            </div>
            <br />
            By choosing not to share the post, you’ll miss out on our{" "}
            <b>Top 10 Interview Questions</b> feature, which can greatly enhance
            your chances of acing your job interviews.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant={ButtonVariant.TEXT}>
            Decline
          </Button>
          <Button onClick={handleAgree} sx={{ fontWeight: "bold" }} autoFocus>
            Accept & Share
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuestionBox;
