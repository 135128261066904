import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { stringAvatar } from "./utils";
import { CARD_HEAD, Typo } from "../Typo";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "../Box";

export const CLAvatar = React.memo(({ name, profileImg, ...rest }) => {
  return (
    <Box className="centered-element pointer-cursor" {...rest}>
      <Avatar 
      src={profileImg}  
      // src="https://media.licdn.com/dms/image/D4D03AQH0E1ztrj0raA/profile-displayphoto-shrink_200_200/0/1674140834567?e=1680739200&v=beta&t=0pJpj3Wv7CWkeKTqrJQ7iPxYJmuS7xEOrJGjHPDVU_0"
      />
      <Box style={{ marginLeft: "0.4rem" }}>
        <Typo typoFor={CARD_HEAD}>{name}</Typo>
      </Box>
      <ArrowDropDownIcon />
    </Box>
  );
});

CLAvatar.displayName = "Avatar";
