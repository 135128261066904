import { Box, Container, Stack } from '@mui/material'
import React from 'react'
import { CARD_CONTENT, CARD_HEADING, Typo } from '../../CLComponents'
import './Card.css'
const Card = () => {
    const CardDetails = [
        {
            id:1,
            img: 'Assets/Vector (2).svg',
            title: 'Get Tailored Fit Cover Letters in Seconds',
            desc: 'Coverly customizes your cover letter to <strong>match your experience</strong> with the job requirements and <strong>what hiring managers want, drastically improving</strong> your chances of getting hired.<br/><br/>In fact, our service has been shown to increase interview invitations, according to our satisfied customers.',
            imageBackgroundColor: '#4E95FE',

        },
        {
            id:2,
            img: 'Assets/Vector (1).svg',
            title: 'Coverly is here to save you from all that manual work',
            desc: 'Our AI-powered tool generates <strong>personalized cover letters</strong> in just seconds, <strong>highlighting your skills</strong> and <strong>experience</strong> that <strong>best match the job requirements</strong>, all while staying true to who you really are.<br/><br/>You can trust our algorithms to <strong>analyze</strong> the job posting and <strong>extract the most relevant information</strong>, so you can focus on what matters most: <strong>preparing for your interview.</strong>',
            imageBackgroundColor: '#FFA800',

        },
        {
            id:3,
            img: 'Assets/Vector.svg',
            title: 'Tailored Cover Letters, Automatically Created from Your LinkedIn Profile and Job Post',
            desc: "Stand out from the competition with Coverly's unique AI technology. Our service automatically pulls your professional data from LinkedIn. It extracts all relevant information from the job post link you provide, <strong>enabling our system to generate a perfectly tailored cover letter instantly.</strong><br/><br/>Our advanced algorithms <strong>highlight your skills and experiences that truly set you apart from the competition,</strong> making sure your cover letter is tailored to the specific needs of the hiring manager. Join Coverly for free today and streamline your job application process!",
            imageBackgroundColor: '#28CBFF',

        },
    ]
    return (
        <>
            <Container sx={{ marginTop: "5rem" }} >
                <Stack direction={{ xs: 'column',md: "column",lg:"row" }} spacing={{ md: 4, xs: 5 }} sx={{width:{xs:"100%"}}}    >
                    {CardDetails.map((card) => {
                        return (
                            <Box className='card-style' key={card.id} sx={{marginTop: { xs: "20px 0"}}} >
                                <Stack direction={"column"} width={'20rem'} spacing={2} textAlign={'center'} alignItems={'center'}  >
                                    <Box>
                                        <img className='card-image' style={{ backgroundColor: `${card.imageBackgroundColor}` }} src={card.img} alt='Vector' />
                                    </Box>
                                    <Box>
                                        <Typo typoFor={CARD_HEADING} >
                                            {card.title}
                                        </Typo>
                                    </Box>
                                    <Box>
                                        <Typo typoFor={CARD_CONTENT}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: card.desc,
                                                }}
                                            ></span>
                                        </Typo>
                                    </Box>
                                </Stack>
                            </Box>
                        )
                    })

                    }
                </Stack>
            </Container>
        </>
    )
}

export default Card