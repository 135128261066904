import * as React from "react";
import Textarea from "@mui/joy/Textarea";
import { PLACEHOLDER_TEXT, Typo } from "../Typo";
import { FormHelperText } from "@mui/material";
import { Box } from "../Box";

export const TextArea = React.memo(
  ({
    textAreaValue,
    onChangeHandler,
    maxChar = false,
    error = false,
    placeholder = "Type in here…",
  }) => {
    const handleOnChange = (event) => {
      const { value } = event.target;
      if (
        (maxChar && textAreaValue?.length < maxChar) ||
        !textAreaValue ||
        textAreaValue?.length > value.length ||
        !maxChar
      ) {
        onChangeHandler(value);
      }
    };

    return (
      <Box>
        <Textarea
          placeholder={placeholder}
          value={textAreaValue}
          onChange={handleOnChange}
          minRows={6}
          sx={{
            borderColor: "#8C8C8C",
          }}
          endDecorator={
            maxChar ? (
              <Typo typoFor={PLACEHOLDER_TEXT}>
                {textAreaValue?.length || 0}/{maxChar} character(s)
              </Typo>
            ) : (
              ""
            )
          }
        />
        <FormHelperText className="error-msg">
          {error || (maxChar && textAreaValue?.length > maxChar)
            ? error || "Max length exceed"
            : ""}
        </FormHelperText>
      </Box>
    );
  }
);

TextArea.displayName = "TextArea";
