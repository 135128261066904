// This property will define the style of
export const ButtonVariant = {
  PRIMARY: "primary-button",
  SECONDARY: "secondary-button",
  TEXT: "text-button",
};

export const ButtonSize = {
  SMALL: "small",
  REGULAR: "medium",
  LARGE: "large",
};
