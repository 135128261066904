import { Card, CardContent, Stack } from "@mui/material";
import React, { useCallback } from "react";
import {
  Box,
  ButtonSize,
  ButtonVariant,
  CARD_CONTENT,
  CARD_HEAD,
  IconButton,
  LINK,
  Paper,
  Typo,
} from "../../CLComponents";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { GENERATE_LETTER_PAGE } from "../../config/Routes/Constants";
import JobHeader from "../JobHeader/JobHeader";
import { useNavigate } from "react-router-dom";
import "./LetterCard.css";
import "../../App.css";
import { useAuth } from "../../Context/userContext";

function LetterCard({ letter }) {
  const navigate = useNavigate();
  const { job_logo, location, title, job_link, letter_content, id } = letter;
  const { userProfileData } = useAuth();

  const handleOpenCoverLetter = useCallback(() => {
    window.dataLayer.push({
      event: "view_cover_letter_button",
      email: userProfileData?.email,
      letter_link: GENERATE_LETTER_PAGE + id,
    });
    return navigate(GENERATE_LETTER_PAGE + id);
  }, [id, userProfileData?.email]);

  return (
    <Card>
      <CardContent>
        <Stack
          spacing={4}
          direction={{ md: "row", xs: "column" }}
          alignItems={{ md: "center" }}
        >
          <Box>
            <JobHeader
              job_logo={job_logo}
              title={title}
              location={location}
              job_link={job_link}
            />

            <Box className="tuncated-letter">
              <Typo typoFor={CARD_CONTENT}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: letter_content,
                  }}
                ></span>
              </Typo>
            </Box>
          </Box>

          <IconButton
            size={ButtonSize.REGULAR}
            variant={ButtonVariant.SECONDARY}
            onClick={handleOpenCoverLetter}
          >
            View cover letter
            <ArrowForwardIosIcon
              sx={{ fontSize: "1rem", marginLeft: "0.4rem" }}
            />
          </IconButton>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default LetterCard;
