import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import { makeButtonClasses } from "./utils";
import { ButtonSize, ButtonVariant } from "./types";
import MuiIconButton from "@mui/material/IconButton";
import "./Button.css";
import "../../App.css";

const StyledButton = styled(MuiButton)(() => ({
  "&.Mui-disabled": {
    cursor: "not-allowed",
    pointerEvents: "auto",
    opacity: 0.5,
  },
  textTransform: "none",
}));

const StyledLoadingButton = styled(LoadingButton)(() => {
  return {
    "& .MuiCircularProgress-root": {},
    "& .MuiLoadingButton-root": {
      width: "4rem",
      height: "4rem",
    },
  };
});

export const Button = React.memo(
  ({
    children,
    disabled = false,
    isLoading,
    onClick,
    size = ButtonSize.REGULAR,
    variant = ButtonVariant.PRIMARY,
    fullWidth = false,
    ...rest
  }) => {
    const loadingParams = React.useMemo(() => {
      return {
        variant,
        disabled: true,
        hideIcon: true, //we need to hide start icon button in loading cases
        fullWidth,
      };
    }, [variant, fullWidth]);

    const buttonParams = React.useMemo(() => {
      return {
        variant,
        disabled: disabled ?? false,
        hideIcon: false,
        fullWidth,
      };
    }, [variant, disabled, fullWidth]);

    if (!!isLoading) {
      return (
        <StyledLoadingButton
          classes={makeButtonClasses(loadingParams)}
          loading
          size={ButtonSize[size]}
          {...rest}
        >
          {children}
        </StyledLoadingButton>
      );
    }
    return (
      <StyledButton
        classes={makeButtonClasses(buttonParams)}
        disabled={disabled}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        size={size}
        {...rest}
        onClick={onClick}
        // sx={{padding:"1rem"}}
      >
        {children}
      </StyledButton>
    );
  }
);

Button.displayName = "Button";

export const IconButton = React.memo(
  ({
    children,
    disabled = false,
    isLoading,
    onClick,
    size = ButtonSize.REGULAR,
    variant = ButtonVariant.PRIMARY,
    fullWidth = false,
    ...rest
  }) => {
    const loadingParams = React.useMemo(() => {
      return {
        variant,
        disabled: true,
        hideIcon: true, //we need to hide start icon button in loading cases
        fullWidth,
        iconButton: true,
      };
    }, [variant, fullWidth]);

    const buttonParams = React.useMemo(() => {
      return {
        variant,
        disabled: disabled ?? false,
        hideIcon: false,
        fullWidth,
        iconButton: true,
      };
    }, [variant, disabled, fullWidth]);

    if (!!isLoading) {
      return (
        <StyledLoadingButton
          classes={makeButtonClasses(loadingParams)}
          loading
          size={ButtonSize[size]}
          {...rest}
        >
          {children}
        </StyledLoadingButton>
      );
    }
    return (
      <MuiIconButton
        classes={makeButtonClasses(buttonParams)}
        disabled={disabled}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        size={size}
        {...rest}
        onClick={onClick}
      >
        {children}
      </MuiIconButton>
    );
  }
);
IconButton.displayName = "IconButton";
