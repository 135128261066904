import { Divider, MenuItem, Stack } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  ButtonSize,
  ButtonVariant,
  CARD_HEAD_SECONDARY,
  H2,
  IconButton,
  LINK_SECONDARY,
  Loader,
  Menu,
  SnackBar,
  SnackBarVariant,
  Typo,
  CLModal,
  Button,
  TextArea,
} from "../../CLComponents";
import JobHeader from "../../Components/JobHeader/JobHeader";
import MainLayout from "../../Layout/MainLayout/MainLayout";
import RefreshIcon from "@mui/icons-material/Refresh";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import CheckIcon from "@mui/icons-material/Check";
import { useParams } from "react-router-dom";
import jsPDF from "jspdf";
import "./CoverLetter.css";
import {
  fetchCoverLetter,
  generateCoverLetter,
} from "../../Services/API/CoverLetter";
import {
  GENERATING_LETTER,
  SUCCESSFULLY_LETTER_GENERATED,
  TRY_LETTER,
} from "../../Utils/Messages";
import { COVER_LETTER_URL } from "../../Utils/Constants";
import CardSection from "../../Components/CardsSection/CardSection";
import { useNavigate } from "react-router-dom";
import { GENERATE_LETTER_PAGE } from "../../config/Routes/Constants";
import { useAuth } from "../../Context/userContext";
import LetterRating from "../../Components/LetterRating/LetterRating";
import Question from "../../Components/TenQuestions/Main";
import { Helmet } from "react-helmet";
import { NotFound } from "../NotFound";

const AdditionalInfoModal = ({
  loader,
  createCoverLetter,
  isOpenModal,
  setIsOpenModal,
}) => {
  const [additionalInfo, setAdditionalInfo] = React.useState(undefined);
  const [additionalInfoError, setAdditionalInfoError] = React.useState(null);

  const handleAdditionalInfo = useCallback((value) => {
    setAdditionalInfo(value);
    setAdditionalInfoError(null);
  }, []);

  const handleModalSubmit = useCallback(() => {
    if (additionalInfo && additionalInfo.length) {
      setIsOpenModal(false);
      createCoverLetter(additionalInfo)();
    } else {
      setAdditionalInfoError("please enter additional information!");
    }
  }, [additionalInfo]);

  return (
    <CLModal
      open={isOpenModal}
      setOpen={setIsOpenModal}
      slots={{ backdrop: loader ? Loader : null }}
    >
      <Stack spacing={2}>
        <Typo typoFor={CARD_HEAD_SECONDARY}>Add Additional Info:</Typo>
        <TextArea
          textAreaValue={additionalInfo}
          onChangeHandler={handleAdditionalInfo}
          maxChar={1000}
          error={additionalInfoError}
          placeholder="I am applying for python developer position. so, focus more on python skill."
        />
        <Box>
          <Button onClick={handleModalSubmit}>Generate</Button>
        </Box>
      </Stack>
    </CLModal>
  );
};

const GenerateLetterMenu = ({
  menuAnchor,
  setMenuAnchor,
  createCoverLetter,
  loader,
  isOpenModal,
  setIsOpenModal,
}) => {
  const openAdditionalInfoModal = useCallback(() => {
    setIsOpenModal(true);
  }, [isOpenModal]);

  const { userProfileData } = useAuth();

  return (
    <>
      <Menu
        id="generate-letter-btn"
        anchorEl={menuAnchor}
        manageAnchorEl={setMenuAnchor}
        withArrow={false}
      >
        <MenuItem>
          <Typo onClick={createCoverLetter()}>Generate cover letter again</Typo>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Typo onClick={openAdditionalInfoModal}>
            Add specific request for generating letter
          </Typo>
        </MenuItem>
      </Menu>

      <AdditionalInfoModal
        loader={loader}
        createCoverLetter={createCoverLetter}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </>
  );
};

export function CoverLetter() {
  const { id } = useParams();
  const coverLetterRef = useRef(null);
  const { userProfileData } = useAuth();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [currentLetter, setCurrentLetter] = useState(null);
  const [relatedLetter, setRelatedLetter] = useState({
    nextPage: null,
    letters: [],
  });
  const [isLetterCopied, setIsLetterCopied] = useState(false);
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [createCoverLetterLoader, setCreateCoverLetterLoader] = useState(false);
  const [errorInFetchingCoverLetter, setErrorInFetchingCoverLetter] = useState(false);

  //? states for snackbars
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  const [msgType, setMsgType] = useState(SnackBarVariant.SUCCESS);
  const [allowAutoHide, setAllowAutoHide] = useState(true);

  const [generateBtn, setGenerateBtn] = useState(null);

  const getCoverLetter = useCallback(
    (apiURL = COVER_LETTER_URL) =>
      () => {
        const params = {
          access_token: localStorage.getItem("linkedin_token"),
          letter_id: id,
        };
        setLoader(true);
        fetchCoverLetter(params, apiURL)
          .then((data) => {
            const { cover_letter, related_cover_letter } = data.data;
            const { next, results } = related_cover_letter;
            setErrorInFetchingCoverLetter(false);
            setCurrentLetter(cover_letter);
            if (apiURL === COVER_LETTER_URL) {
              setRelatedLetter({
                nextPage: next,
                letters: results,
              });
            } else {
              setRelatedLetter((prevState) => {
                return {
                  nextPage: next,
                  letters: prevState["letters"].concat(results),
                };
              });
            }
            setLoader(false);
          })
          .catch((error) => {
            setErrorInFetchingCoverLetter(true);
            setLoader(false);
            handleSnackBar(TRY_LETTER, SnackBarVariant.ERROR, false);
          });
      },
    [relatedLetter, id]
  );

  useEffect(() => {
    getCoverLetter()();
  }, [id]);

  const handleSnackBar = useCallback(
    (msg, msgType = SnackBarVariant.SUCCESS, allowAutoHide = true) => {
      setSnackBarMsg(msg);
      setMsgType(msgType);
      setAllowAutoHide(allowAutoHide);
      setIsSnackBarOpen(true);
    },
    []
  );

  const copyLetterToClipBoard = () => {
    window.dataLayer.push({
      event: "copy_button",
      email: userProfileData?.email,
      letter_id: id,
    });
    const coverLetter = document.getElementById("coverLetterContainer");
    navigator.clipboard.writeText(coverLetter.innerText);
    setIsLetterCopied(true);
    setTimeout(() => {
      setIsLetterCopied(false);
    }, 2000);
  };

  const downloadLetterAsPdf = useCallback(() => {
    window.dataLayer.push({
      event: "download_button",
      email: userProfileData?.email,
      letter_id: id,
    });
    const coverLetter = document.getElementById("coverLetterContainer");
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.setFontSize(13);
    const lines = pdf.splitTextToSize(coverLetter?.innerText, 180);
    pdf.text(15, 20, lines);
    pdf.save(
      userProfileData?.first_name +
        " " +
        userProfileData?.last_name +
        "-" +
        currentLetter?.title +
        ".pdf"
    );
  }, [currentLetter?.title, userProfileData]);

  const createCoverLetter = useCallback(
    (additionalInfoText = false) =>
      () => {
        window.dataLayer.push({
          event: "generate_letter_page_button",
          email: userProfileData?.email,
          job_link: currentLetter?.job_link,
          with_additional_info: !!additionalInfoText,
        });

        const jobLinkParts = (currentLetter?.job_link).split("/");
        const jobId = jobLinkParts[jobLinkParts.length - 2];
        if (!jobId) {
          handleSnackBar(TRY_LETTER, SnackBarVariant.ERROR, false);
          return false;
        }

        const reqBody = {
          access_token: localStorage.getItem("linkedin_token"),
          job_id: jobId,
          from_new_page: true,
          additional_info: additionalInfoText,
        };
        setGenerateBtn(null);
        setLoader(true);
        setCreateCoverLetterLoader(true);
        generateCoverLetter(reqBody)
          .then((data) => {
            const { cover_letter } = data.data;
            setIsOpenModal(false);
            handleSnackBar(SUCCESSFULLY_LETTER_GENERATED);
            setCreateCoverLetterLoader(false);
            return navigate(GENERATE_LETTER_PAGE + cover_letter.id);
          })
          .catch((error) => {
            setLoader(false);
            setCreateCoverLetterLoader(false);
            setIsOpenModal(false);
            handleSnackBar(TRY_LETTER, SnackBarVariant.ERROR, false);
          });
      },
    [currentLetter]
  );

  const handleMenu = useCallback((event) => {
    setGenerateBtn(event.currentTarget);
  }, []);

  return (
    <MainLayout>
      <Helmet>
        <title>Coverly.AI - Cover Letter</title>
      </Helmet>
      <Box className="full-screen-container">
        {loader ? (
          <Loader
            size={50}
            text={createCoverLetterLoader ? GENERATING_LETTER : ""}
          />
        ) : (
          <></>
        )}
        {errorInFetchingCoverLetter ? (
          <NotFound />
        ) : (
          <Stack
            sx={{ px: { sm: "5rem", xs: "1rem" }, mt: "10rem" }}
            spacing={3}
          >
            <Typo typoFor={H2}>Generated cover letter</Typo>
            <Stack
              direction={{ md: "row" }}
              justifyContent="space-between"
              spacing={{ xs: 2, sm: 2, lg: 0 }}
            >
              <JobHeader
                job_logo={currentLetter?.job_logo}
                title={currentLetter?.title}
                location={currentLetter?.location}
                job_link={currentLetter?.job_link}
                logoSize="5rem"
                jobTitleVarient={CARD_HEAD_SECONDARY}
                jobLinkVarient={LINK_SECONDARY}
              />
              <Box className="column-direction-justify-centered-element">
                <IconButton
                  size={ButtonSize.REGULAR}
                  variant={ButtonVariant.SECONDARY}
                  // onClick={createCoverLetter}
                  onClick={handleMenu}
                >
                  <RefreshIcon sx={{ paddingRight: "0.4rem" }} />
                  Generate new
                </IconButton>
              </Box>
            </Stack>
            <Box
              component="div"
              contentEditable={true}
              dangerouslySetInnerHTML={{
                __html: currentLetter?.letter_content,
              }}
              className="letter-text-area"
              // forwardRef={coverLetterRef}
              forwardref={coverLetterRef}
              id="coverLetterContainer"
            />
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              justifyContent="space-between"
            >
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <IconButton
                  size={ButtonSize.REGULAR}
                  variant={ButtonVariant.PRIMARY}
                  onClick={copyLetterToClipBoard}
                >
                  {isLetterCopied ? (
                    <>
                      <CheckIcon sx={{ paddingRight: "0.4rem" }} />
                      Copied
                    </>
                  ) : (
                    <>
                      <ContentCopyIcon sx={{ paddingRight: "0.4rem" }} />
                      Copy
                    </>
                  )}
                </IconButton>
                <IconButton
                  size={ButtonSize.REGULAR}
                  variant={ButtonVariant.SECONDARY}
                  onClick={downloadLetterAsPdf}
                >
                  Download pdf
                  <SystemUpdateAltIcon sx={{ paddingLeft: "0.4rem" }} />
                </IconButton>
              </Stack>

              <LetterRating
                rating={currentLetter?.rating}
                setCurrentLetter={setCurrentLetter}
                handleSnackBar={handleSnackBar}
              />
            </Stack>
            <Question jobLink={currentLetter?.job_link} />

            {relatedLetter?.letters?.length ? (
              <Box sx={{ pt: "3rem" }}>
                <CardSection
                  cardSectiontitle="Your Previous Letters for This Job"
                  coverLetters={relatedLetter?.letters}
                  nextPage={relatedLetter?.nextPage}
                  getCoverLetters={getCoverLetter}
                />
              </Box>
            ) : (
              <></>
            )}
          </Stack>
        )}
      </Box>
      <SnackBar
        msg={snackBarMsg}
        isOpen={isSnackBarOpen}
        msgType={msgType}
        snackBarManager={setIsSnackBarOpen}
        allowAutoHide={allowAutoHide}
      />
      <GenerateLetterMenu
        menuAnchor={generateBtn}
        setMenuAnchor={setGenerateBtn}
        createCoverLetter={createCoverLetter}
        loader={loader}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </MainLayout>
  );
}
