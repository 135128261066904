import { Stack } from "@mui/material";
import React from "react";
import { Box, H4, LINK, Typo } from "../../CLComponents";
import LetterCard from "../LetterCard/LetterCard";

function CardSection({
  cardSectiontitle,
  coverLetters,
  nextPage,
  getCoverLetters,
}) {
  return (
    <Stack sx={{ pb: "5rem" }}>
      <Typo typoFor={H4}>{cardSectiontitle}</Typo>
      <Stack spacing={3} mt={2}>
        {coverLetters?.map((letter, key) => {
          return (
            <LetterCard
              data-aos="zoom-in"
              key={`${key}_${letter?.job_link}`}
              letter={letter}
            />
          );
        })}
      </Stack>
      {nextPage ? (
        <Stack justifyContent="center" alignItems="center" mt={2}>
          <Typo typoFor={LINK} onClick={getCoverLetters(nextPage)}>
            View more
          </Typo>
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
}

export default CardSection;
