import { Backdrop, CircularProgress } from "@mui/material";
import * as React from "react";
import { Box } from "../Box";
import { LOADER_TEXT, Typo } from "../Typo";
import ReactLoading from "react-loading";
import "../../App.css";
import { BACKGROUND_COLOR } from "../../Utils/Theme";

export const Loader = React.memo(
  ({
    text,
    size = 32,
    backdropPosition = "fixed",
    backdropBackgroundColor = "rgba(0, 0, 0, 0.5)",
  }) => {
    return (
      <Backdrop
        sx={{
          backgroundColor: backdropBackgroundColor,
          zIndex: 100,
          position: backdropPosition,
        }}
        open={true}
      >
        <Box className="column-direction-centered-element">
          {/* <CircularProgress size={size} /> */}
          <ReactLoading type="bars" color={BACKGROUND_COLOR} />
          {text ? (
            <Typo typoFor={LOADER_TEXT}>
              <span
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              ></span>
              {/* {text} */}
            </Typo>
          ) : (
            <></>
          )}
        </Box>
      </Backdrop>
    );
  }
);

Loader.displayName = "Loader";
