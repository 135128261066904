import axios from "axios";
import {
  LINKEDIN_LOGIN_URL,
  LINKEDIN_ACCESS_TOKEN,
  USER_DATA_FROM_LINKEDIN,
} from "../../Utils/Constants";

export const getLoginURL = async () => {
  return await axios.get(LINKEDIN_LOGIN_URL);
};

export const getAccessToken = async (body) => {
  return await axios.post(LINKEDIN_ACCESS_TOKEN, body);
};

export const getUserDataFromLinkedin = async (body) => {
  return await axios.post(USER_DATA_FROM_LINKEDIN, body);
};

export const isValidAccessToken = async (accessToken) => {
  return await axios.get(LINKEDIN_ACCESS_TOKEN, { params: accessToken });
};
