import axios from "axios";
import { COVER_LETTER_URL, UPDATE_RATING } from "../../Utils/Constants";

export const generateCoverLetter = async (body) => {
  return await axios.post(COVER_LETTER_URL, body);
};

export const fetchCoverLetter = async (params, apiURL = COVER_LETTER_URL) => {
  return await axios.get(apiURL, { params: params });
};

export const updateRating = async (body) => {
  return await axios.patch(UPDATE_RATING, body);
};
