import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CARD_HEAD_SECONDARY,
  CLModal,
  CLRating,
  SnackBarVariant,
  TextArea,
  Typo,
} from "../../CLComponents";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  ERROR_COLOR,
  PRIMARY_COLOR,
  SUCCESS_COLOR,
  YELLOW_COLOR,
} from "../../Utils/Theme";
import { Stack, Tooltip } from "@mui/material";
import { FEEDBACK_POSTING_FAILED, FEEDBACK_TEXT } from "../../Utils/Messages";
import { useParams } from "react-router-dom";
import { updateRating } from "../../Services/API/CoverLetter";
import { useAuth } from "../../Context/userContext";

const RatingCases = ({ rating }) => {
  if (rating === 1 || rating === 2) {
    return (
      <Tooltip title="Your Rating: Dissatisfied">
        <ThumbDownAltIcon
          sx={{
            fontSize: "2rem",
            color: ERROR_COLOR,
          }}
        />
      </Tooltip>
    );
  } else if (rating === 3) {
    return (
      <Tooltip title="Your Rating: Neutral">
        <DragHandleIcon
          sx={{
            fontSize: "2rem",
            color: YELLOW_COLOR,
          }}
        />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Your Rating: Satisfied">
      <ThumbUpAltIcon
        sx={{
          fontSize: "2rem",
          color: SUCCESS_COLOR,
        }}
      />
    </Tooltip>
  );
};

const RatingModal = ({
  isRatingModalOpen,
  setIsRatingModalOpen,
  feedbackType,
  setCurrentLetter,
  handleSnackBar,
}) => {
  const { id } = useParams();
  const [additionalFeedback, setAdditionalFeedback] = useState(undefined);
  const [ratingValue, setRatingValue] = useState(
    feedbackType === "positive" ? 5 : 2
  );
  const { userProfileData } = useAuth();

  useEffect(() => {
    setRatingValue(feedbackType === "positive" ? 5 : 2);
  }, [feedbackType]);

  const handleRatingChange = useCallback(
    (e) => {
      setRatingValue(e.target.value);
    },
    [ratingValue]
  );

  const submitFeedback = useCallback(() => {
    window.dataLayer.push({
      event: "submit_feedback_button",
      email: userProfileData?.email,
      letter_id: id,
    });

    const body = {
      letter_id: id,
      access_token: localStorage.getItem("linkedin_token"),
      rating: parseInt(ratingValue),
      feedback: additionalFeedback,
    };

    updateRating(body)
      .then((data) => {
        setCurrentLetter(data.data);
        setIsRatingModalOpen(false);
      })
      .catch((error) => {
        handleSnackBar(FEEDBACK_POSTING_FAILED, SnackBarVariant.ERROR, false);
        setIsRatingModalOpen(false);
      });
  }, [additionalFeedback, ratingValue]);

  return (
    <CLModal
      open={isRatingModalOpen}
      setOpen={setIsRatingModalOpen}
      // slots={{ backdrop: loader ? Loader : null }}
    >
      <Stack spacing={2}>
        <Typo typoFor={CARD_HEAD_SECONDARY}>Provide Additional feedback</Typo>
        <Box>
          <CLRating
            defaultValue={ratingValue}
            onChangeHandler={handleRatingChange}
          />
        </Box>
        <TextArea
          textAreaValue={additionalFeedback}
          onChangeHandler={setAdditionalFeedback}
          maxChar={1000}
          placeholder={FEEDBACK_TEXT}
        />
        <Box>
          <Button onClick={submitFeedback}>Submit Feedback</Button>
        </Box>
      </Stack>
    </CLModal>
  );
};

function LetterRating({ rating, setCurrentLetter, handleSnackBar }) {
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState(null);

  const handleOnClick = useCallback(
    (type) => () => {
      setFeedbackType(type);
      setIsRatingModalOpen(true);
    },
    []
  );

  if (rating) {
    return <RatingCases rating={rating} />;
  } else {
    return (
      <Box>
        <ThumbUpAltIcon
          onClick={handleOnClick("positive")}
          sx={{
            "&:hover": { color: SUCCESS_COLOR, fontSize: "2.2rem" },
            fontSize: "2rem",
            cursor: "pointer",
            color: "rgb(32,191,241)",
          }}
        />
        <ThumbDownAltIcon
          onClick={handleOnClick("negative")}
          sx={{
            "&:hover": { color: ERROR_COLOR, fontSize: "2.2rem" },
            fontSize: "2rem",
            cursor: "pointer",
            color: "rgb(32,191,241)",
          }}
        />
        <RatingModal
          isRatingModalOpen={isRatingModalOpen}
          setIsRatingModalOpen={setIsRatingModalOpen}
          feedbackType={feedbackType}
          setCurrentLetter={setCurrentLetter}
          handleSnackBar={handleSnackBar}
        />
      </Box>
    );
  }
}

export default LetterRating;
