export const SESSION_EXPIRED_ERROR = "Session Expired! Please Sign-In again :)";
export const USER_VALIDATION_ERROR =
  "Something went wrong while validating User. Please try after some time!";
export const FAILED_TO_FETCH_PROFILE =
  "Something went wrong while fetching user details.";
export const TRY_LETTER = "Something went wrong! Please try again later.";
export const SUCCESSFUL_LOGIN = "Logged in Successfully! Redirecting...";
export const ERROR_WHILE_GENERATING_LETTER =
  "Something went wrong while generating cover letter! Please after some time!";
export const SUCCESSFULLY_LETTER_GENERATED =
  "Cover letter was successfully generated.";
export const REQUIRED_FIELD = "This Field is Required!";
export const INVALID_VANITY = "Not a Valid LinkedIn Profile!";
export const INVALID_EMAIL = "Not a Valid Email!";
export const GENERATING_LETTER =
  "We are generating your tailor made cover letter.<br/>It usually takes 15-20 seconds";
export const FEEDBACK_TEXT = "What do you think about this cover letter?";
export const FEEDBACK_POSTING_FAILED =
  "Something went wrong. while posting feedback. Please try after some time!";
export const VALIDATING_DATA =
  "Auto-Validating your data from Linkedin Server!";
export const SUCCESSFULLY_VALIDATED_DATA =
  "Validation Done! Redirecting to home page...";
export const NOT_ABLE_TO_VALIDATE =
  "Auto-Validation failed! Please fill details manually";
export const SUCCESSFULLY_QUESTIONS_GENERATED=
  "Top 10 questions was successfully gennerated";
export const FAILED_TO_POST_ON_LINKEDIN = 
  "Not able to post on linkedin! please try later"
export const FAILED_QUESTIONS_GENERATED = 
  "Not able to generate questions! please try later"
export const GENERATING_TOP_QUESTIONS=
  "Generating top questions.<br/> it usually take 5-10 seconds"
