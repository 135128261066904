import * as React from "react";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import { Box } from "../Box";

const reactionIconStyle = {
  fontSize: "2.6rem",
};

const customIcons = {
  1: {
    icon: (
      <Box>
        <SentimentVeryDissatisfiedIcon color="error" sx={reactionIconStyle} />
      </Box>
    ),
    label: "Very Dissatisfied",
  },
  2: {
    icon: (
      <Box>
        <SentimentDissatisfiedIcon color="error" sx={reactionIconStyle} />
      </Box>
    ),
    label: "Dissatisfied",
  },
  3: {
    icon: (
      <Box>
        <SentimentSatisfiedIcon color="warning" sx={reactionIconStyle} />
      </Box>
    ),
    label: "Neutral",
  },
  4: {
    icon: (
      <Box>
        <SentimentSatisfiedAltIcon color="success" sx={reactionIconStyle} />
      </Box>
    ),
    label: "Satisfied",
  },
  5: {
    icon: (
      <Box>
        <SentimentVerySatisfiedIcon color="success" sx={reactionIconStyle} />
      </Box>
    ),
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <Box {...other}>{customIcons[value].icon}</Box>;
}

const StyledRating = styled(Rating)(({ theme, fullWidth }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
  // "& .MuiRating-label": {
  //   display: "none",
  //   backgroundColor: "red",
  //   // display: "flex",
  //   // justifyContent: "space-around",
  // },
  // // width: fullWidth ? "100%" : "auto",
  // // display: "flex",
  // // justifyContent: "space-around",
  // // backgroundColor: "red",
}));

export const CLRating = React.memo(
  ({ onChangeHandler, fullWidth = true, defaultValue = 5 }) => {
    return (
      <StyledRating
        name="highlight-selected-only"
        value={defaultValue}
        IconContainerComponent={IconContainer}
        getLabelText={(value) => customIcons[value].label}
        fullWidth={fullWidth}
        onChange={onChangeHandler}
        highlightSelectedOnly
      />
    );
  }
);

CLRating.displayName = "CLRating";
