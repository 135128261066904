import * as React from "react";
import { makePaperClasses } from "./utils";
import { Paper as MUIPaper } from "@mui/material";

export const Paper = React.memo(
  ({ children, variant = "outlined", elevation = 0 }) => {
    return (
      <MUIPaper
        variant={variant}
        elevation={elevation}
        classes={makePaperClasses()}
      >
        {children}
      </MUIPaper>
    );
  }
);

Paper.displayName = "Paper";
