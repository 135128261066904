import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  H4,
  Loader,
  SnackBar,
  SnackBarVariant,
  Typo,
} from "../../CLComponents";
import QuestionBox from "./QuestionBox";
import { useAuth } from "../../Context/userContext";
import {
  getTopQuestions,
  postTopQuestions,
} from "../../Services/API/TopQuestions";
import {
  FAILED_QUESTIONS_GENERATED,
  FAILED_TO_POST_ON_LINKEDIN,
  GENERATING_TOP_QUESTIONS,
  SUCCESSFULLY_QUESTIONS_GENERATED,
} from "../../Utils/Messages";

const Questions = ({ jobLink }) => {
  //usestate for top questions
  const [questions, setQuestions] = useState(
    "<p>1. What experience do you have with computer vision and natural language processing?<br>2. What techniques do you use for data cleaning, feature engineering and data pre-processing?<br>3. How have you used machine learning algorithms such as deep learning, convolutional neural networks and recurrent neural networks (RNNs)?<br>4. What methods have you used to identify patterns, trends and insights in large datasets?<br>5. Can you describe your experience developing and deploying scalable models in production?"
  );
  const [isClick, setIsClick] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const { userProfileData, setUserProfileData } = useAuth();
  const [isConfirm, setIsConfirm] = useState(false);
  const [jobId, setjobId] = useState(null);

  //? states for snackbars
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  const [msgType, setMsgType] = useState(SnackBarVariant.SUCCESS);
  const [allowAutoHide, setAllowAutoHide] = useState(true);

  //loader for top questions
  const [loader, setLoader] = useState(false);
  const [creatingTopQuestions, setCreatingTopQuestions] = useState(false);

  useEffect(() => {
    setIsConfirm(userProfileData?.is_coverly_shared);
  }, [userProfileData?.is_coverly_shared]);

  useEffect(() => {
    if (jobLink) {
      const jobLinkParts = jobLink.split("/");
      const jobId = jobLinkParts[jobLinkParts.length - 2];
      setjobId(jobId);
    }
  }, [jobLink]);

  const handleSnackBar = useCallback(
    (msg, msgType = SnackBarVariant.SUCCESS, allowAutoHide = true) => {
      setSnackBarMsg(msg);
      setMsgType(msgType);
      setAllowAutoHide(allowAutoHide);
      setIsSnackBarOpen(true);
    },
    []
  );
  const handleAgree = () => {
    setCreatingTopQuestions(true);
    setIsConfirm(true);
    const getBody = {
      access_token: localStorage.getItem("linkedin_token"),
      job_id: jobId,
    };
    setLoader(true);
    postTopQuestions(getBody)
      .then((res) => {
        setQuestions(res.data?.questions);
        setCreatingTopQuestions(false);
        setIsClick(true);
        setLoader(false);
        setUserProfileData({ ...userProfileData, is_coverly_shared: true });
        handleSnackBar(SUCCESSFULLY_QUESTIONS_GENERATED);
      })
      .catch((error) => {
        setIsClick(false);
        setLoader(false);
        setCreatingTopQuestions(false);
        handleSnackBar(FAILED_TO_POST_ON_LINKEDIN, SnackBarVariant.ERROR);
        console.log(error);
      });
  };
  const handleClick = () => {
    window.dataLayer.push({
      event: "unlock_top_questions_button",
      email: userProfileData?.email,
      job_id: jobId,
    });

    if (isConfirm) {
      setCreatingTopQuestions(true);
      const getBody = {
        access_token: localStorage.getItem("linkedin_token"),
        job_id: jobId,
      };
      setLoader(true);
      postTopQuestions(getBody)
        .then((res) => {
          setQuestions(res.data?.questions);
          handleSnackBar(SUCCESSFULLY_QUESTIONS_GENERATED);
          setIsClick(true);
          setLoader(false);
        })
        .catch((error) => {
          setIsClick(false);
          setLoader(false);
          setCreatingTopQuestions(false);
          handleSnackBar(FAILED_QUESTIONS_GENERATED, SnackBarVariant.ERROR);
          console.log(error);
        });
    } else {
      setIsDialog(true);
    }
  };
  useEffect(() => {
    const getTopQuestion = () => {
      const params = {
        access_token: localStorage.getItem("linkedin_token"),
        job_id: jobId,
      };
      // console.log(jobId);
      getTopQuestions(params)
        .then((res) => {
          if (res.data.length === 0) {
            setIsClick(false);
          } else {
            setIsClick(true);
            setQuestions(res.data[0]?.questions);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getTopQuestion();
  }, [jobId]);

  return (
    <>
      <Box sx={{ position: "relative", pt: "3rem" }}>
        {loader ? (
          <Loader
            size={50}
            text={creatingTopQuestions ? GENERATING_TOP_QUESTIONS : ""}
          />
        ) : (
          <></>
        )}
        <Typo sx={{ ...H4, margin: "2rem 0rem 1rem 0rem" }}>
          Top 10 Interview Questions for this Job
        </Typo>
        <QuestionBox
          isClick={isClick}
          questions={questions}
          isConfirm={isConfirm}
          setQuestions={setQuestions}
          setIsConfirm={setIsConfirm}
          setIsClick={setIsClick}
          handleAgree={handleAgree}
          isDialog={isDialog}
          setIsDialog={setIsDialog}
        />
        <Button
          sx={{
            fontSize: "16px",
            position: "absolute",
            left: { lg: "35rem", md: "21rem", sm: "12rem", xs: "4rem" },
            top: { lg: "18rem", md: "21rem", sm: "24rem", xs: "30rem" },
            display: isClick && "none",
          }}
          onClick={handleClick}
          children={"Unlock Top Questions"}
        />
      </Box>
      <SnackBar
        msg={snackBarMsg}
        isOpen={isSnackBarOpen}
        msgType={msgType}
        snackBarManager={setIsSnackBarOpen}
        allowAutoHide={allowAutoHide}
      />
    </>
  );
};

export default Questions;
