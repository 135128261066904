import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Button, HIGHLIGHT_COLOR, TITLE, Typo } from "../../CLComponents";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./Features.css";

const FEATURES_DETAILS = [
  {
    id: 1,
    imagePostion: "left",
    img: "/Assets/letter-rafiki.svg",
    title: "Creates Cover Letters Tailored Fit For Every Job Post",
    desc: "With a lot of applicants competing for a job post, you certainly wonder how to get your name on the top choice. Make it exciting for everyone. Coverly helps you make cover letters that stand out, designed to help you shine in whatever job you're applying for.",
  },
  {
    id: 2,
    imagePostion: "right",
    img: "/Assets/cleanup-pana.svg",
    title: "ATS-optimized to increase success rates",
    desc: `More and more companies are using <b>Applicant Tracking Systems (ATS) to automatically filter job candidates,</b> which means an individual's efforts to apply for a job are often going in vain as their applications go into a black hole.<br/><br/>Luckily, Coverly uses its <b>artificial intelligence</b> algorithms to make sure that every word in your CV is one-step closer to <b>getting hired.</b>`,
  },
  {
    id: 3,
    imagePostion: "left",
    img: "/Assets/WEWEEE.svg",
    title: "Easy-to-use platform",
    desc: "Coverly helps you create cover letters that are unique for every job post, saving you time with it copy-and-paste format. Just plug in the Linkedin job posting link and we’ll do all the heavy lifting for you.",
  },
  {
    id: 4,
    imagePostion: "right",
    img: "/Assets/Writing-a-letter.svg",
    title:
      "Wide usability to make sure you can be every company's dream applicant",
    desc: "Coverly has an AI model specifically trained on the best practices for writing cover letters for 20+ different industries. The BEST part? It supports 25+ languages so you can be successful anywhere you are in the world.",
  },
  {
    id: 5,
    imagePostion: "left",
    img: "/Assets/message-cuate.svg",
    title: "A tracking system that keeps you updated",
    desc: "Designed to give you a 360-degree overview of almost everything, Coverly can help you track all your job applications and cover letters, and can help you find the right job post based on your expertise.",
  },
  {
    id: 6,
    imagePostion: "right",
    img: "/Assets/text-pana.svg",
    title: "Edit Your Cover Letter Anytime",
    desc: "Coverly has a built-in spell checker and text editor that lets you add your personal taste to every cover letter it generates.",
  },
];

const Features = ({ sinIn, handleSnackBar }) => {
  return (
    <Box
      className="featureContainer"
      sx={{
        padding: { lg: "1rem 16rem", xs: "1rem 4rem" },
        marginTop: { lg: "4rem", xs: "1rem" },
      }}
    >
      <Box sx={{ marginTop: "4rem" }}>
        <Typo typoFor={TITLE}>
          What Is In Store For You? Here Are Some of the
          {window.screen.width > "768" && <br />}
          <Typo component="span" sx={{ ...HIGHLIGHT_COLOR, ...TITLE }}>
            {" "}
            Things Coverly Is Good At
          </Typo>
        </Typo>
      </Box>
      {FEATURES_DETAILS.map((feature) => {
        if (feature.imagePostion === "left" || window.screen.width <= "768") {
          return (
            <Stack
              key={feature.id}
              direction={{ xs: "column", md: "row", sm: "column" }}
              spacing={{ xs: 1, md: 10 }}
              marginTop={{ xs: "4rem", md: "4rem" }}
              display={"flex"}
              textAlign={"left"}
            >
              <Box className="imgBox">
                <img
                  src={feature.img}
                  className="featureImg"
                  alt="letter-rafiki"
                />
              </Box>
              <Box className="descBox">
                <Typography
                  sx={{
                    fontSize: "1.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  {feature.title}
                </Typography>
                <br />
                <Typography
                  sx={{
                    fontSize: "1.4rem",
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: feature.desc,
                    }}
                  ></span>
                </Typography>
              </Box>
            </Stack>
          );
        } else {
          return (
            <Stack
              key={feature.id}
              direction={{ xs: "column", md: "row", sm: "column" }}
              spacing={{ xs: 1, md: 10 }}
              marginTop={{ xs: "4rem", md: "4rem" }}
              textAlign={"left"}
            >
              <Box className="descBox">
                <Typography
                  sx={{
                    fontSize: "1.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  {feature.title}
                </Typography>
                <br />
                <Typography
                  sx={{
                    fontSize: "1.4rem",
                    fontFamily: "Roboto",
                    fontWeight: "300",
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: feature.desc,
                    }}
                  ></span>
                </Typography>
              </Box>
              <Box className="imgBox">
                <img
                  src={feature.img}
                  className="featureImg"
                  alt="letter-rafiki"
                />
              </Box>
            </Stack>
          );
        }
      })}
      <Box margin={"4rem 0px"} sx={{ width: { xs: "100%" } }}>
        <Button
          sx={{ padding: "1rem", width: { xs: "90%", sm: "50%" } }}
          onClick={sinIn(handleSnackBar)}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: { xs: "0.8rem", md: "16px" },
            }}
          >
            TRY IT FREE FOR 14 DAYS <ArrowRightAltIcon />
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Features;
