import axios from "axios";
import { TOP_QUESTIONS } from "../../Utils/Constants";

export const getTopQuestions = async (params) => {
  return await axios.get(TOP_QUESTIONS, {params:params});
}
export const postTopQuestions = async (body) => {
  return await axios.post(TOP_QUESTIONS, body);
}

