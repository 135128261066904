import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stack } from "@mui/material";
import {
  Box,
  ButtonSize,
  ButtonVariant,
  CLTextField,
  H2,
  H4,
  IconButton,
  LINK,
  Loader,
  SnackBar,
  SnackBarVariant,
  SUB_TITLE,
  Typo,
} from "../../CLComponents";
import { useAuth } from "../../Context/userContext";
import MainLayout from "../../Layout/MainLayout/MainLayout";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./GenerateLetter.css";
import LetterCard from "../../Components/LetterCard/LetterCard";
import {
  fetchCoverLetter,
  generateCoverLetter,
} from "../../Services/API/CoverLetter";
import { COVER_LETTER_URL } from "../../Utils/Constants";
import {
  ERROR_WHILE_GENERATING_LETTER,
  GENERATING_LETTER,
  SUCCESSFULLY_LETTER_GENERATED,
  TRY_LETTER,
} from "../../Utils/Messages";
import { useNavigate } from "react-router-dom";
import CardSection from "../../Components/CardsSection/CardSection";
import { BASE_URL, GENERATE_LETTER_PAGE } from "../../config/Routes/Constants";
import { Helmet } from "react-helmet";

const PageHead = ({ generateCoverLetter }) => {
  const jobLinkRef = useRef(null);
  const [jobLinkError, setJobLinkError] = useState(null);
  const { userProfileData } = useAuth();

  const handleJobLinkSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const urlPattern = /currentJobId=\d+/;

      if (!jobLinkRef.current.value) {
        setJobLinkError("Job Link is missing");
        return false;
      }

      if (urlPattern.test(jobLinkRef.current.value)) {
        const jobId = new URL(
          jobLinkRef.current.value ||
            "https://www.linkedin.com/jobs/collections/recommended/"
        ).searchParams.get("currentJobId");

        if (!jobId) {
          setJobLinkError("Invalid Job Link!");
          return false;
        }
        window.dataLayer.push({
          event: "generate_letter_page_button",
          email: userProfileData?.email,
          job_link: jobLinkRef?.current?.value,
        });
        generateCoverLetter(jobId)();
      } else {
        const regex = /\/jobs\/view\/(\d+)/;
        const match = jobLinkRef.current.value.match(regex);

        if (match) {
          const jobId = match[1];
          window.dataLayer.push({
            event: "generate_letter_page_button",
            email: userProfileData?.email,
            job_link: jobLinkRef?.current?.value,
          });
          generateCoverLetter(jobId)();
        } else {
          setJobLinkError("Job Link is missing or Invalid!");
          return false;
        }
      }
    },
    [userProfileData?.email]
  );

  const clearErrorMsg = useCallback(() => {
    setJobLinkError(null);
  }, []);

  return (
    <Box className="head-container-after-login">
      <Typo typoFor={H2}>Paste Linkedin Job Post Link Below</Typo>
      <Typo typoFor={SUB_TITLE}>
        Copy a job post link from LinkedIn and paste it in{" "}
        {window.screen.width > 425 ? <br /> : ""}
        the box below to generate a cover letter for it
      </Typo>
      <Box
        component="form"
        className="head-input-container"
        onSubmit={handleJobLinkSubmit}
        onChange={clearErrorMsg}
        name="job-link-form"
      >
        <Box sx={{ width: { md: "30rem", sm: "auto" } }}>
          <CLTextField
            id="Job Link"
            placeholder="Paste Linkedin job post link here"
            fullWidth={true}
            name="job-link-field"
            inputRef={jobLinkRef}
            error={jobLinkError}
          />
        </Box>
        <IconButton
          size={ButtonSize.REGULAR}
          variant={ButtonVariant.PRIMARY}
          type="submit"
        >
          Generate cover letter
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export function GenerateLetter() {
  const { authed, globalLoader } = useAuth();

  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [createCoverLetterLoader, setCreateCoverLetterLoader] = useState(false);
  const [generatedLetter, setGeneratedLetter] = useState(null);

  //? states for snackbars
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  const [msgType, setMsgType] = useState(SnackBarVariant.SUCCESS);
  const [allowAutoHide, setAllowAutoHide] = useState(true);

  useEffect(() => {
    if (!authed && !globalLoader) {
      navigate(BASE_URL);
    }
  }, [authed, globalLoader]);

  const handleSnackBar = useCallback(
    (msg, msgType = SnackBarVariant.SUCCESS, allowAutoHide = true) => {
      setSnackBarMsg(msg);
      setMsgType(msgType);
      setAllowAutoHide(allowAutoHide);
      setIsSnackBarOpen(true);
    },
    []
  );

  const getCoverLetters = useCallback(
    (apiURL = COVER_LETTER_URL) =>
      () => {
        const params = {
          access_token: localStorage.getItem("linkedin_token"),
        };
        setLoader(true);
        fetchCoverLetter(params, apiURL)
          .then((data) => {
            const { next, results } = data.data;
            if (generatedLetter) {
              setGeneratedLetter((prevState) => {
                return {
                  nextPage: next,
                  letters: prevState["letters"].concat(results),
                };
              });
            } else {
              setGeneratedLetter({
                nextPage: next,
                letters: results,
              });
            }
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            handleSnackBar(TRY_LETTER, SnackBarVariant.ERROR, false);
          });
      },
    [generatedLetter]
  );

  const createCoverLetter = useCallback(
    (jobId) => () => {
      const reqBody = {
        access_token: localStorage.getItem("linkedin_token"),
        job_id: jobId,
      };
      setLoader(true);
      setCreateCoverLetterLoader(true);
      generateCoverLetter(reqBody)
        .then((data) => {
          const { next, results } = data.data;
          setGeneratedLetter({
            nextPage: next,
            letters: results,
          });
          setLoader(false);
          setCreateCoverLetterLoader(false);
          handleSnackBar(SUCCESSFULLY_LETTER_GENERATED);
          return navigate(GENERATE_LETTER_PAGE + results[0].id);
        })
        .catch((error) => {
          let errorMessage = ERROR_WHILE_GENERATING_LETTER;
          if (error?.response?.data?.detail) {
            errorMessage = error.response.data.detail;
          }
          setLoader(false);
          setCreateCoverLetterLoader(false);
          handleSnackBar(
            errorMessage,
            SnackBarVariant.ERROR,
            false
          );
        });
    },
    []
  );

  useEffect(() => {
    getCoverLetters()();
  }, []);

  return (
    <MainLayout>
      <Helmet>
        <title>Coverly.AI - Generate Letter</title>
      </Helmet>
      <Box className="full-screen-container">
        {loader || globalLoader ? (
          <Loader
            size={50}
            text={createCoverLetterLoader ? GENERATING_LETTER : ""}
          />
        ) : (
          <></>
        )}
        <Stack sx={{ px: { sm: "5rem", xs: "1rem" } }} spacing={6}>
          <PageHead generateCoverLetter={createCoverLetter} />
          {generatedLetter?.letters?.length ? (
            <CardSection
              cardSectiontitle={
                <Typo typoFor={H4}>Previously Generated Cover Letters</Typo>
              }
              coverLetters={generatedLetter?.letters}
              nextPage={generatedLetter?.nextPage}
              getCoverLetters={getCoverLetters}
            />
          ) : (
            <></>
          )}
        </Stack>
      </Box>
      <SnackBar
        msg={snackBarMsg}
        isOpen={isSnackBarOpen}
        msgType={msgType}
        snackBarManager={setIsSnackBarOpen}
        allowAutoHide={allowAutoHide}
      />
    </MainLayout>
  );
}
