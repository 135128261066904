import { ParamsBasedRoute } from "./ParamsBasedRoute";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import {
  BASE_URL,
  GENERATE_LETTER_PAGE,
  PERSONAL_DETAILS_FORM,
} from "./Constants";
import { GenerateLetter, CoverLetter, Login, NotFound } from "../../Pages/";
import { Landing } from "../../Pages/LandingPage";

export const AppRoutes = [
  {
    path: BASE_URL,
    element: (
      <PublicRoute>
        <Landing />
      </PublicRoute>
    ),
  },
  {
    path: PERSONAL_DETAILS_FORM,
    element: (
      <ParamsBasedRoute params="code">
        <Login />
      </ParamsBasedRoute>
    ),
  },
  {
    path: GENERATE_LETTER_PAGE,
    element: (
      <PublicRoute>
        <GenerateLetter />
      </PublicRoute>
    ),
  },
  {
    path: `${GENERATE_LETTER_PAGE}:id`,
    element: (
      <PrivateRoute>
        <CoverLetter />
      </PrivateRoute>
    ),
  },
  {
    path: "*",
    element: (
      <PublicRoute>
        <NotFound />
      </PublicRoute>
    ),
  },
];
