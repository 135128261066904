import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../Context/userContext";
import { BASE_URL, GENERATE_LETTER_PAGE } from "./Constants";

// This Component will give the access if and only if the user is authenticated
export const ParamsBasedRoute = ({ children, params }) => {
  const { authed } = useAuth();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isLinkedinTokenAvailable = localStorage.getItem("linkedin_token");

  if (urlParams.get(params) && !authed) {
    return children;
  } else {
    if(authed){
      return <Navigate to={GENERATE_LETTER_PAGE} />;
    }
    return <Navigate to={BASE_URL} />;
  }
};
