import React, { useCallback } from "react";
import { Stack } from "@mui/material";
import {
  Box,
  CARD_HEAD,
  CARD_HEAD_SECONDARY,
  LINK,
  Typo,
} from "../../CLComponents";
import { useAuth } from "../../Context/userContext";

function JobHeader({
  job_logo,
  title,
  location,
  job_link,
  logoSize = "3rem",
  jobTitleVarient = CARD_HEAD,
  jobLinkVarient = LINK,
}) {
  const addDefaultLogo = useCallback((e) => {
    e.target.src = "/Assets/default-comapny-logo.svg";
  }, []);

  const { userProfileData } = useAuth();

  const handleJobLink = useCallback(
    (url) => () => {
      window.dataLayer.push({
        event: "open_job_link",
        email: userProfileData?.email,
        url_to_open: url,
      });
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    },
    [job_link, userProfileData?.email]
  );

  return (
    <Stack direction="row" spacing={2}>
      <Box>
        <img
          src={job_logo}
          alt="alt text"
          style={{
            height: logoSize,
            width: logoSize,
            borderRadius: "0.4rem",
          }}
          onError={addDefaultLogo}
        />
      </Box>

      <Box className="column-direction-justify-evenly-element">
        <Typo typoFor={jobTitleVarient}>
          {title} | {location}
        </Typo>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Typo typoFor={jobLinkVarient} onClick={handleJobLink(job_link)}>
            {job_link}
          </Typo>
        </Box>
        <Box sx={{ display: { sm: "none" } }}>
          <Typo typoFor={jobLinkVarient} onClick={handleJobLink(job_link)}>
            Go to Job
          </Typo>
        </Box>
      </Box>
    </Stack>
  );
}

export default JobHeader;
