import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { Navigate } from "react-router-dom";
import { SnackBar, SnackBarVariant } from "../CLComponents";
import { BASE_URL } from "../config/Routes/Constants";
import { getLoginURL, isValidAccessToken } from "../Services/API/Auth";
import { userProfile } from "../Services/API/User";
import {
  FAILED_TO_FETCH_PROFILE,
  SESSION_EXPIRED_ERROR,
  TRY_LETTER,
  USER_VALIDATION_ERROR,
} from "../Utils/Messages";

// Create the context
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authed, setAuthed] = useState(
    !!localStorage.getItem("linkedin_token") || false
  );
  // const [authed, setAuthed] = useState(false);
  const [userProfileData, setUserProfileData] = useState(null);
  const [globalLoader, setGlobalLoader] = useState(false);
  const [authLoader, setAuthLoader] = useState(true);
  //? states for snackbars
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [globalError, setGlobalError] = useState(null);

  useEffect(() => {
    // localStorage.clear();
    fetchUserData();
  }, []);

  const fetchAndSetUserProfileData = async (apiParams) => {
    return await userProfile(apiParams);
  };

  const fetchUserData = async (shouldValidateToken = true) => {
    const linkedinToken = localStorage.getItem("linkedin_token");

    if (linkedinToken) {
      setGlobalLoader(true);
      const apiParams = { access_token: linkedinToken };
      if (shouldValidateToken) {
        await isValidAccessToken(apiParams)
          .then((tokenStatus) => {
            const { active } = tokenStatus.data;
            if (active) {
              fetchAndSetUserProfileData(apiParams)
                .then((userData) => {
                  const { data } = userData.data;
                  setUserProfileData(data);
                  setAuthed(true);
                  setAuthLoader(false);
                  return true;
                })
                .catch((error) => {
                  setGlobalError({
                    error: FAILED_TO_FETCH_PROFILE,
                    varient: SnackBarVariant.ERROR,
                    autoHide: false,
                  });
                  setIsSnackBarOpen(true);
                  setAuthLoader(false);
                  logout();
                  return false;
                });
            } else {
              setGlobalError({
                error: SESSION_EXPIRED_ERROR,
                varient: SnackBarVariant.ERROR,
                autoHide: true,
              });
              setIsSnackBarOpen(true);
              setTimeout(() => {
                logout();
              }, 5000);
            }
          })
          .catch(() => {
            setGlobalError({
              error: USER_VALIDATION_ERROR,
              varient: SnackBarVariant.ERROR,
              autoHide: false,
            });
            setIsSnackBarOpen(true);
            setTimeout(() => {
              logout();
            }, 1000);
            return false;
          })
          .finally(() => {
            setGlobalLoader(false);
          });
      } else {
        return await fetchAndSetUserProfileData(apiParams);
      }
    }
  };

  const logout = async () => {
    window.dataLayer.push({
      event: "logout_button",
      email: userProfileData?.email,
    });
    setAuthed(false);
    setUserProfileData(null);
    setAuthLoader(false);
    localStorage.clear();
    return <Navigate to={BASE_URL} />;
  };

  const sinIn = useCallback(
    (handleSnackBar) => () => {
      window.dataLayer.push({
        event: "signin_landing_page_button",
      });
      getLoginURL()
        .then((loginData) => {
          const { login_url } = loginData.data;
          window.open(login_url, "_self");
        })
        .catch((error) => {
          // TODO: try to use dynamic error message
          handleSnackBar(TRY_LETTER, SnackBarVariant.ERROR, false);
        });
    },
    []
  );

  // return !globalLoader ? (
  // Using the provider so that ANY component in our application can
  // use the values that we are sending.
  return (
    <AuthContext.Provider
      value={{
        authed,
        userProfileData,
        setAuthed,
        logout,
        globalLoader,
        setGlobalLoader,
        fetchUserData,
        setUserProfileData,
        sinIn,
        authLoader,
      }}
    >
      {children}
      <SnackBar
        msg={globalError?.error}
        isOpen={isSnackBarOpen}
        msgType={globalError?.varient}
        snackBarManager={setIsSnackBarOpen}
        allowAutoHide={globalError?.autoHide}
      />
    </AuthContext.Provider>
  );
};

// Finally creating the custom hook
export const useAuth = () => useContext(AuthContext);
