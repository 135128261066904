import React from "react";
import LoginForm from "../../Components/LoginForm/LoginForm";
import MainLayout from "../../Layout/MainLayout/MainLayout";

export function Login() {
  return (
    <MainLayout>
      <LoginForm />
    </MainLayout>
  );
}
