import React from "react";
import { SwipeableDrawer } from "@mui/material";
import { Box } from "../Box/Box";
import { makeDrawerClasses } from "./utils";
import "./Drawer.css";

const toggleDrawer = (open, setIsDrawerOpen) => (event) => {
  if (
    event &&
    event.type === "keydown" &&
    (event.key === "Tab" || event.key === "Shift")
  ) {
    return;
  }
  setIsDrawerOpen(open);
};

const list = (setIsDrawerOpen, DrawerList) => {
  return (
    <Box
      role="presentation"
      onClick={toggleDrawer(false, setIsDrawerOpen)}
      onKeyDown={toggleDrawer(false, setIsDrawerOpen)}
    >
      {DrawerList}
    </Box>
  );
};

export const Drawer = React.memo(
  ({ isDrawerOpen, setIsDrawerOpen, DrawerList }) => {
    return (
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false, setIsDrawerOpen)}
        onOpen={toggleDrawer(true, setIsDrawerOpen)}
        classes={makeDrawerClasses()}
      >
        {list(setIsDrawerOpen, DrawerList)}
      </SwipeableDrawer>
    );
  }
);

Drawer.displayName = "Drawer";
