import memoizeOne from "memoize-one";

export const makeButtonClasses = memoizeOne(
  ({ variant, hideIcon, fullWidth, iconButton = false }) => {
    return {
      root: `${
        fullWidth
          ? `full-screen-btn ${variant} ${iconButton ? "icon-btn" : ""}`
          : iconButton
          ? `icon-btn ${variant}`
          : variant
      }`,
      sizeSmall: "height-demo",
      sizeMedium: "medium-button",
      sizeLarge: "large-btn",
      iconSizeSmall: ``,
      iconSizeMedium: ``,
      iconSizeLarge: ``,
      disabled: "disabled",
    };
  }
);
