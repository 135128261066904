import { INVALID_VANITY, REQUIRED_FIELD } from "./Messages";

export const validateVanity = (vanityName) => {
  //? RegEX to check entered vanity format
  const vanityRegx =
    /^https?:\/\/(www\.)?linkedin\.com\/(mwlite\/)?in\/[^\/]+\/?$/;

  // ? supported URLs
  // https://www.linkedin.com/in/ivankovicivan
  // http://www.linkedin.com/in/ivankovicivan
  // https://linkedin.com/in/ivankovicivan
  // http://linkedin.com/in/ivankovicivan
  // https://www.linkedin.com/mwlite/in/ivankovicivan
  // http://www.linkedin.com/mwlite/in/ivankovicivan
  // https://linkedin.com/mwlite/in/ivankovicivan
  // http://linkedin.com/mwlite/in/ivankovicivan

  const vanityBase = "www.linkedin.com";
  const validVanity = "https://www.linkedin.com/in/";
  let vanity = vanityName;

  if (!vanity) {
    return {
      validVanity: false,
      errMsg: REQUIRED_FIELD,
      vanity: vanity,
    };
  }

  if (!vanity.includes(vanityBase)) {
    vanity = `${validVanity}${vanity}/`;
  }

  if (!vanityRegx.test(vanity)) {
    return {
      validVanity: false,
      errMsg: INVALID_VANITY,
      vanity: vanity,
    };
  } else {
    return {
      validVanity: true,
      errMsg: null,
      vanity: vanity,
    };
  }
};
