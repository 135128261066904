// import { PRIMARY_COLOR } from "../../Utils/Theme";

export const CARD_HEAD = {
  fontWeight: 500,
  fontFamily: "Poppins",
};

export const CARD_HEADING ={
  fontWeight: 700, 
  fontSize: "20px",
  fontFamily: "Poppins",
}

export const HIGHLIGHT_COLOR={
 background:"linear-gradient(99.3deg, #4E95FE 4.4%, #20BFF1 104.15%)",
 WebkitTextFillColor: "transparent",
 WebkitBackgroundClip: "text",
}

export const CARD_HEAD_SECONDARY = {
  fontWeight: 400,
  fontSize: "1.2rem",
  fontFamily: "Poppins",
};

export const CARD_CONTENT = {
  fontFamily: "Roboto",
  lineHeight: "1.6rem",
  fontWeight:300
};

export const TITLE = {
  fontSize:'2.4rem',
  fontFamily:"Poppins",
  fontWeight:700,
  lineHeight:"45px",
}


export const LINK = {
  fontFamily: "Poppins",
  cursor: "pointer",
  background:"linear-gradient(99.3deg, #4E95FE 4.4%, #20BFF1 104.15%)",
 WebkitTextFillColor: "transparent",
 WebkitBackgroundClip: "text",
};

export const LINK_SECONDARY = {
  fontFamily: "Poppins",
  cursor: "pointer",
  background:"linear-gradient(99.3deg, #4E95FE 4.4%, #20BFF1 104.15%)",
 WebkitTextFillColor: "transparent",
 WebkitBackgroundClip: "text",
  fontSize: "1.2rem",
};

export const SUB_TITLE = {
  fontFamily: "Poppins",
  color: "#181818",
  fontSize: {xs:"0.8rem",sm:"1.2rem"},
};

export const H4 = {
  fontFamily: "Poppins",
  fontSize: {xs:"1.8rem",sm:"2.4rem"},
  lineHeight:{xs:"2rem",sm:"3rem"},
  background:"linear-gradient(99.3deg, #4E95FE 4.4%, #20BFF1 104.15%)",
 WebkitTextFillColor: "transparent",
 WebkitBackgroundClip: "text",
  fontWeight: 600,
};

export const H2 = {
  fontFamily: "Poppins",
  fontSize: {xs:"2.4rem",sm:"3.2rem"},
  lineHeight:{xs:"45px",sm:"3.5rem"},
  background:"linear-gradient(99.3deg, #4E95FE 4.4%, #20BFF1 104.15%)",
 WebkitTextFillColor: "transparent",
 WebkitBackgroundClip: "text",
  fontWeight: 600,
};

export const PLACEHOLDER_TEXT = {
  fontFamily: "Roboto",
  color: "#8C8C8C",
  marginLeft: "auto",
};

export const LOADER_TEXT = {
  fontFamily: "Roboto",
  color: "#F0FAFF",
  marginTop: "0.5rem",
  fontSize: "1.2rem",
  textAlign: "center",
};

export const TESTIMONY_NAME={
  fontFamily: "Poppins", 
  fontSize: "1.4rem", 
  fontWeight: 800
}

export const TESTIMONY_SUBTITLE={
  fontFamily: "Roboto", 
  fontSize: "1.2rem", 
  fontWeight: 400
}

export const TESTIMONY = {
  fontFamily: "Roboto", 
  fontSize: "1.2rem", 
  fontWeight: 500,
  color:"#636363"
}

export const QUESTION_TEXT = {
  fontFamily: "Poppins", 
  fontSize: "1.2rem",
  lineHeight:"2.4rem",
  padding:"0rem 1rem"
}

export const FOOTER_TEXT={
  fontFamily: "Poppins", 
  fontSize: "14px", 
  color:"white",
  fontWeight:400
}