import React from "react";
import { Box, Button, ButtonVariant, LINK, Typo } from "../../CLComponents";
import "./NotFound.css";
import { BASE_URL } from "../../config/Routes/Constants";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../Layout/MainLayout/MainLayout";

export function NotFound() {
  const navigate = useNavigate();

  const redirectToSafePlace = () => {
    navigate(BASE_URL);
  };

  return (
    <MainLayout>
      <Box className="not-found-page column-direction-centered-element">
        <img src="/Assets/notfound.svg" alt="404" className="notfoundimg" />
        <Button onClick={redirectToSafePlace}>
          <Typo>click to visit safe place!</Typo>
        </Button>
      </Box>
    </MainLayout>
  );
}
