// URLs
const BASE_URL = process.env.REACT_APP_API_SOURCE;
const AUTH_MODULE = "auth/";
export const LINKEDIN_LOGIN_URL = BASE_URL + AUTH_MODULE + "login";
export const LINKEDIN_ACCESS_TOKEN = BASE_URL + AUTH_MODULE + "access-token";
export const USER_DATA_FROM_LINKEDIN = BASE_URL + AUTH_MODULE + "user-data";

// Users Profile
export const USER_PROFILE_DATA_URL = BASE_URL + AUTH_MODULE + "profile";

// Cover-Letter
export const COVER_LETTER_URL = BASE_URL + "cover-letter/";
export const UPDATE_RATING = COVER_LETTER_URL + "rating/";
export const TOP_QUESTIONS = BASE_URL + "cover-letter/top-questions/";
