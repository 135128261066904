import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AppRoutes } from "./config/Routes/";
// import AOS from "aos";
import ReactGA from "react-ga";
import "aos/dist/aos.css";
import "./App.css";

const router = createBrowserRouter(AppRoutes);
const TRACKING_ID = process.env.REACT_APP_GA_ID;
ReactGA.initialize(TRACKING_ID);

function App() {
  // useEffect(() => {
  //   AOS.init();
  // }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
