import { Box, Container } from "@mui/material";
import React from "react";
import {
  HIGHLIGHT_COLOR,
  TESTIMONY,
  TESTIMONY_NAME,
  TESTIMONY_SUBTITLE,
  TITLE,
  Typo,
} from "../../CLComponents";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./Testimony.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

const TESTIMONY_DETAILS = [
  {
    id: 1,
    img: "Assets/letter-bro.svg",
    name: "Peter, Accounting Manager",
    sub_text: "“Coverly made job hunting so much easier for me.”",
    testimony:
      "As an accounting manager, I highly recommend Coverly for anyone looking for an easy way to create professional cover letters. The tool generated personalized cover letters that highlighted my skills and experience, resulting in more interview invitations. Coverly made job hunting so much easier for me.",
  },
  {
    id: 2,
    img: "Assets/o1.png",
    name: "Samantha, Marketing Coordinator",
    sub_text:
      "“Coverly helped me get past the initial screening process and land an interview.”",
    testimony:
      "Using Coverly was a game changer in my job search as a marketing coordinator. The tool helped me create a personalized cover letter in seconds, highlighting my relevant skills and experiences for the job I was really excited about. Coverly helped me get past the initial screening process and land an interview with the company. I would definitely recommend Coverly to anyone looking for an easier and more effective way to apply for jobs.",
  },
  {
    id: 3,
    img: "Assets/o3.png",
    name: "Michael, Customer Service Representative",
    sub_text:
      "“Using Coverly saved me so much time and stress in my job search.”",
    testimony:
      "Coverly was a lifesaver in my job search as a customer service representative. The tool created a cover letter tailored to the job I was applying for, highlighting my relevant skills and experience. It made me stand out from other applicants and helped me get past the initial screening process. Using Coverly saved me so much time and stress in my job search, and it was definitely worth it.",
  },
  {
    id: 4,
    img: "Assets/o2.png",
    name: "Sarah, Executive Assistant",
    sub_text:
      "“Coverly helped me make a strong impression and land an interview.”",
    testimony:
      "I used Coverly to apply for an Executive Assistant job and it was a game changer. The tool generated a personalized cover letter that matched the job requirements, highlighted my relevant skills and experience, and made me stand out from other applicants. Coverly helped me make a strong impression and land an interview. I highly recommend Coverly to anyone looking for an easier and more effective way to apply for jobs.",
  },
];

const Testimony = () => {
  return (
    <Container sx={{ marginTop: "6rem", textAlign: "center" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typo sx={{ ...TITLE, marginBottom: "20px" }}>
            Hear What Other People Have To Say
            <Typo component="span" sx={{ ...HIGHLIGHT_COLOR, ...TITLE }}>
              {" "}
              About Coverly
            </Typo>
          </Typo>
        </Box>
        <Box>
          <Typo typoFor={TESTIMONY_SUBTITLE}>
            Stand out. Get in. See why more and more people are getting hooked
            with Coverly
          </Typo>
        </Box>
        <Box sx={{ marginTop: "5rem", width: { xs: "100%", md: "70%" } }}>
          <Swiper
            navigation={true}
            modules={[Navigation]}
            style={{ paddingTop: "2rem" }}
          >
            {TESTIMONY_DETAILS.map((test) => {
              return (
                <SwiperSlide key={test.id} className="swiperSlider">
                  <img
                    src={test.img}
                    style={{ borderRadius: "50%", marginTop: "-2rem" }}
                    alt={test.img}
                  />
                  <Typo typoFor={TESTIMONY_NAME}>{test.name}</Typo>
                  <br />
                  <Typo
                    typoFor={{
                      ...TESTIMONY_SUBTITLE,
                      color: "#636363",
                      fontStyle: "italic",
                      fontWeight: "700",
                    }}
                  >
                    {test.sub_text}
                  </Typo>
                  <br />
                  <Typo
                    typoFor={{
                      ...TESTIMONY,
                      padding: { xs: "0px 4rem", md: "0px 8rem" },
                      marginBottom: "2rem",
                    }}
                  >
                    {test.testimony}
                  </Typo>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Box>
    </Container>
  );
};

export default Testimony;
