import React, { useCallback, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useAuth } from "../../Context/userContext";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  Box,
  Button,
  FOOTER_TEXT,
  HIGHLIGHT_COLOR,
  SnackBarVariant,
  TITLE,
  Typo,
} from "../../CLComponents";
import { useNavigate } from "react-router-dom";
import { GENERATE_LETTER_PAGE } from "../../config/Routes/Constants";
import HomeContent from "../../PageContent/en/Pages/LandingPage/Home.json";
import "./Home.css";
import "../../App.css";
import Card from "../Card/Card";
import Testimony from "../Testimony/Testimony";
import Features from "../Features/Features";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

function Home() {
  const { authed, userProfileData, sinIn, globalLoader } = useAuth();
  const navigate = useNavigate();
  //? states for snackbars
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  const [msgType, setMsgType] = useState(SnackBarVariant.SUCCESS);
  const [allowAutoHide, setAllowAutoHide] = useState(true);

  const handleSnackBar = useCallback(
    (msg, msgType = SnackBarVariant.SUCCESS, allowAutoHide = true) => {
      setSnackBarMsg(msg);
      setMsgType(msgType);
      setAllowAutoHide(allowAutoHide);
      setIsSnackBarOpen(true);
    },
    []
  );

  const handleHomeButton = useCallback(() => {
    if (authed) {
      return navigate(GENERATE_LETTER_PAGE);
    }
    sinIn(handleSnackBar)();
  }, [handleSnackBar]);

  const Heading = () => {
    return (
      <Container sx={{ marginTop: "4rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography className="styled-title" sx={{ ...TITLE }}>
            {HomeContent.title}
            <Typo
              className="styled-title"
              component="span"
              sx={{ ...HIGHLIGHT_COLOR, ...TITLE }}
            >
              Coverly
            </Typo>
          </Typography>

          <Box maxWidth={"630px"}>
            <Typography
              sx={{ fontFamily: "Roboto", fontWeight: "300", fontSize: "18px" }}
            >
              Coverly uses the <strong>latest AI Technology</strong> to create a
              perfectly
              <br />
              tailored{" "}
              <strong className="highlighted-text">COVER LETTER </strong>for
              each job application,
              <strong> instantly!</strong>
            </Typography>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
            <Button
              sx={{ padding: "1rem", width: { xs: "90%" } }}
              onClick={sinIn(handleSnackBar)}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: { xs: "0.8rem", md: "16px" },
                }}
              >
                START LANDING DREAM JOBS <ArrowRightAltIcon />
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>
    );
  };

  const ImageDisplay = () => {
    return (
      <Container
        sx={{
          display: "flex",
          flexDirection: {
            sm: "column",
            md: "column",
            lg: "row",
            xs: "column",
          },
          gap: "1rem",
          justifyContent: "flex-start",
          marginTop: "4rem",
        }}
      >
        <Box>
          <img
            style={{ width: "95%", height: "100%" }}
            src="Assets/Generated cover letter.svg"
            alt="Generated cover letter"
          />
        </Box>
        <Box>
          <img
            style={{ width: "95%", height: "100%" }}
            src="Assets/Paste link.svg"
            alt="Paste link 1"
          />
        </Box>
      </Container>
    );
  };

  const Details = () => {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#f0faff",
          flexDirection: { sm: "column", md: "row", lg: "row", xs: "column" },
          gap: "2rem",
          justifyContent: { md: "flex-start", xs: "center" },
          marginTop: "4rem",
          padding: { xs: "0 16px" },
        }}
      >
        <Box sx={{ flexBasis: "40%" }}>
          <img
            className="coverImg"
            src="Assets/Group 29818.svg"
            alt="Generated cover letter"
          />
        </Box>
        <Box
          sx={{
            flexBasis: "60%",
            textAlign: { xs: "center", lg: "left", md: "left" },
          }}
        >
          <Grid
            container
            rowGap={"2rem"}
            sx={{
              margin: { md: "5rem 0px", xs: "1rem 0px" },
              justifyContent: { xs: "center" },
            }}
          >
            <Grid item md={12}>
              <Typo typoFor={TITLE} className="styled-title">
                “With
                <Typo
                  className="styled-title"
                  component="span"
                  sx={{ ...HIGHLIGHT_COLOR, ...TITLE }}
                >
                  {" "}
                  Coverly
                </Typo>
                , users can quickly and easily create professional,
                <Typo
                  className="styled-title"
                  component="span"
                  sx={{ ...HIGHLIGHT_COLOR, ...TITLE }}
                >
                  {" "}
                  ATS-optimized cover letters that stand out from the
                  competition.”
                </Typo>
              </Typo>
            </Grid>
            <Grid item md={12}>
              <Typography sx={{ fontFamily: "Roboto", fontWeight: "300" }}>
                Simply provide the Linkedin job post link, and our system will
                automatically extract all relevant information, including job
                details, company information, and requirements for you.
              </Typography>
            </Grid>
            <Grid item md={12} sx={{ width: { xs: "100%", sm: "50%" } }}>
              <Button
                sx={{
                  padding: "1rem",
                  width: { xs: "90%", sm: "70%", md: "50%" },
                }}
                onClick={sinIn(handleSnackBar)}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: { xs: "0.8rem", md: "16px" },
                  }}
                >
                  TRY COVERLY FOR FREE IN 14 DAYS <ArrowRightAltIcon />
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const Announcement = () => {
    return (
      <Box
        sx={{
          marginTop: { xs: "10rem", sm: "18rem" },
          marginBottom: { xs: "8rem", sm: "0rem" },
          position: "relative",
          height: "50vh",
        }}
      >
        <img
          src="Assets/img-announcement-1.png"
          className="img-announcement-1"
          alt="wewe2 1"
        />
        <Container className="announcement-container">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
              padding: "2rem 0",
            }}
          >
            <Typography className="styled-title" sx={{ ...TITLE }}>
              Get Your 14-Day Trial Here. For Free.
            </Typography>

            <Box maxWidth={"630px"}>
              <Typography sx={{ fontFamily: "Roboto", fontWeight: "300" }}>
                Not yet ready to commit?
                <br />
                <br />
                Grab your free test drive HERE for 14 days and explore how
                Coverly can help you craft standout cover letters for any job
                application you want to dive into.
              </Typography>
            </Box>
            <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
              <Button
                sx={{ padding: "1rem", width: "90%" }}
                onClick={sinIn(handleSnackBar)}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: { xs: "0.8rem", md: "16px" },
                  }}
                >
                  TRY IT FREE FOR 14 DAYS <ArrowRightAltIcon />
                </Typography>
              </Button>
            </Box>
          </Box>
        </Container>
        <img
          src="Assets/img-announcement-2.png"
          className="img-announcement-2"
          alt="img-announcement-2"
        />
      </Box>
    );
  };

  const Cover = () => {
    return (
      <Container
        sx={{
          display: "flex",
          flexDirection: {
            sm: "column-reverse",
            md: "row",
            lg: "row",
            xs: "column-reverse",
          },
          gap: "2rem",
          justifyContent: "flex-start",
          marginTop: "4rem",
          padding: { xs: "0 16px" },
        }}
      >
        <Box
          sx={{
            flexBasis: "40%",
            display: "flex",
            textAlign: { md: "left", xs: "center" },
          }}
        >
          <Grid
            container
            sx={{
              rowGap: "1rem",
              justifyContent: { xs: "center" },
              alignSelf: "center",
            }}
          >
            <Grid item md={12}>
              <Typo typoFor={TITLE}>
                <Typo component="span" sx={{ ...HIGHLIGHT_COLOR, ...TITLE }}>
                  Impress Any Recruiter{" "}
                </Typo>
                Today and Get Yourself an Interview!
              </Typo>
            </Grid>
            <Grid item md={12}>
              <Typography sx={{ fontFamily: "Roboto", fontWeight: "300" }}>
                Coverly helps you skip the long hours of reading company
                descriptions, and requirements and hunting their employees on
                LinkedIn to learn more about them – just plug in the job posting
                link and Coverly will create tailored cover letters for you in a
                couple of clicks and get that better-than-ever rate!
              </Typography>
            </Grid>
            <Grid item md={12} sx={{ width: { xs: "100%", sm: "50%" } }}>
              <Button
                sx={{ padding: "1rem", width: "90%" }}
                onClick={sinIn(handleSnackBar)}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: { xs: "0.8rem", md: "16px" },
                  }}
                >
                  TRY IT FOR FREE <ArrowRightAltIcon />
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            flexBasis: "60%",
            justifyContent: { xs: "center" },
            display: { xs: "flex" },
          }}
        >
          <img
            className="coverImg"
            src="Assets/Generated cover letter 1.svg"
            alt="Generated cover letter"
          />
        </Box>
      </Container>
    );
  };

  const Footer = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#4E95FE",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "4rem",
          textAlign: "center",
        }}
      >
        <Typo typoFor={FOOTER_TEXT}>
          Copyright © 2023{" "}
          <a
            href="https://www.coverly.ai/"
            className="coverly_link"
            target="_blank"
            rel="noreferrer"
          >
            Coverly.AI
          </a>{" "}
          All rights Reserved.
        </Typo>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>Coverly.AI - Cover Letter Generator</title>
      </Helmet>
      <Box className="home-container">
        <Box className="home-content">
          <Heading />
          <ImageDisplay />
          <Card />
        </Box>
      </Box>
      <Details />
      <Announcement />
      <Testimony />
      <Features sinIn={sinIn} handleSnackBar={handleSnackBar} />
      <Cover />
      <Footer />
    </>
  );
}

export default Home;
